import {
    Box,
    Button,
    Typography,
  } from "@mui/material";
  import {useState } from "react";
  import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
  import {
    rejectEnquiryService,
  } from "../../Services/services";
  import {
    STATUS_200,
    FETCH_FAIL,
    SESSION_REJECTED,
  } from "../../data/constants";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import "./seminarModule.css";
  import { ENQUIRY_REJECTED_SUCCESSFULLY, OK, CANCEL, WANT_TO_PROCEED } from "../../lib/ValidationMessage";
const RejectSeminarEnquiry=({data,setShowRejectConfirmationDialog,fetchEnquiryList})=> {
    const [showLoader, setShowLoader] = useState(false);
    const hideLoaderHandler = () => {
        setShowLoader(false);
      };
      const showLoaderHandler = () => {
        setShowLoader(true);
      };
     
      const closeRejectEnquiryCnfDialog=()=>{
        setShowRejectConfirmationDialog({
            show:false,
            value:''
        })
    }
    const rejectEnquiry = async () => {
        let user_id = data;
        showLoaderHandler();
        const response = await rejectEnquiryService(user_id);
        if (response.status === STATUS_200) {
          if (response?.message === SESSION_REJECTED) {
            toast.success(ENQUIRY_REJECTED_SUCCESSFULLY);
            hideLoaderHandler();
            fetchEnquiryList(1);
          } else {
            toast.error(response?.message);
            hideLoaderHandler();
          }
        } else if (response === FETCH_FAIL) {
            toast.error(response?.message);
          hideLoaderHandler();
         
        } else {
          toast.error(response?.message);
          hideLoaderHandler();
        }
      };
    
    return (
   <>
     <CustomDialog
            title="Alert"
            handleClose={closeRejectEnquiryCnfDialog}
            actions={
              <Box>
                <Button className="cancel-btn" onClick={closeRejectEnquiryCnfDialog}>{CANCEL}</Button>
                <Button className="confirm-btn"
                  onClick={() => {  setShowRejectConfirmationDialog((prevState) => {
                      return { ...prevState, show: false };
                    });
                    rejectEnquiry();}} 
                >
                 {OK}
                </Button>
              </Box>
            }
          >
            <Typography className="alert-text">
             {WANT_TO_PROCEED}
            </Typography>
          </CustomDialog>
   </>
  )
}

export default RejectSeminarEnquiry;