import { Button } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomDialog = ({
  handleClose = () => {},
  handleOpen = () => {},
  title = '',
  subTitle = null,
  actions = '',
  showClose = false,
  ...props
}) => {
  return (
    <Dialog  open={handleOpen} {...props} >
      <DialogTitle>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {title}
          {showClose && (
            <Button sx={{justifyContent: 'flex-end'}} onClick={handleClose}>
              <CloseIcon />
            </Button>
          )}
        </Box>
      </DialogTitle>
      {subTitle && (
        <Typography marginTop={3} marginBottom={2} marginLeft={3}>
          {subTitle}
        </Typography>
      )}
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
