import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import SeminarsParticipantsList from "./SeminarParticipantsList";
import Attendees from "./Attendees";
import "../Seminars/seminarModule.css";
import CustomTabs from "../../Components/CustomTab/CustomTabs";
import { useState } from "react";
import { GO_BACK } from "../../lib/ValidationMessage";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
function SeminarUsers() {
  let navigate = useNavigate();
  let tabItems = [
    {
      label: "Invited users",
      id: 1,
    },

    {
      label: "Attendees",
      id: 2,
    },
  
    
  ];

  const [activeTab, setTabActive] = useState("");
  const [counter, setCounter] = useState(1);
  const onTabClick = (data) => {
    setTabActive(data.id);
  };
const setUpdateTab=(data)=>{
 
  setCounter(a=>a+1);
setTabActive(data)
}

  return (
    <>
      <Box component={'div'} >
        <Grid container className="tab-container">
        
          <Grid item sm={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <KeyboardBackspaceIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate('/seminars');
                  }}
                />
            <CustomTabs count={counter}
            activeTab={activeTab}
              tabList={tabItems}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile={false}
              tabClick={onTabClick}
              tabsProp={{ indicatorColor: "#fff" }}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} marginTop={2}>
          {activeTab === 1 && <SeminarsParticipantsList updateTab={setUpdateTab}/>}
          {activeTab === 2 && <Attendees/>}
        
          
        </Grid>
      </Box>
    </>
  );
}

export default SeminarUsers