import { removeBannerImageService } from "../Services/services";

let api_base_url = '';
const dev_base_url = process.env.REACT_APP_API_URL_DEV;
const prod_base_url = process.env.REACT_APP_API_URL_PROD;

if (dev_base_url !== '') {
  api_base_url = dev_base_url;
} else {
  api_base_url = prod_base_url;
}

let apiConfig = {
  //Seminar Block API's
  createSeminar: `${api_base_url}admin/createSeminar`,
  listSeminar: `${api_base_url}admin/seminarList`,
  deleteSeminar: `${api_base_url}admin/deleteSeminar`,
  updateSeminar: `${api_base_url}admin/updateSeminar`,
  replicateSeminar: `${api_base_url}admin/createDuplicateSeminar`,
  seminarUserList: `${api_base_url}admin/seminarUsersList`,
  manageSeats: `${api_base_url}admin/manageseats`,
  reportList: `${api_base_url}admin/seminarReportList`,
  regEnquiry: `${api_base_url}admin/registerSessionUsersList`,
  rejectEnquiry:`${api_base_url}admin/enquiryStatusUpdate`,
  addSeminarToUsers: `${api_base_url}admin/addSeminarUsers`,
  //cms API
  cmsData:`${api_base_url}common/cmsContent`,
  bannerImage:`${api_base_url}common/homepagebannerimagesList`,
  updatecmsData:`${api_base_url}admin/updateSetting`,
  uploadImage:`${api_base_url}admin/uploadImage`,
  uploadBannerImage:`${api_base_url}admin/UploadHomeBannerImages`,
  removeBannerImage:`${api_base_url}admin/deleteBannerImages`,
  videoList:`${api_base_url}video/allVideoslist`,
  insertVideo:`${api_base_url}admin/insertVideo`,
  deleteVideo:`${api_base_url}admin/deleteVideo`,
  publishVideo:`${api_base_url}admin/videoPublish`,
  //Dashboard Login API
  adminLogin: `${api_base_url}/admin/login`,
  //user info Api
  userDetails:`${api_base_url}admin/AdminInfoById`,
  updateUserDetails:`${api_base_url}admin/editAdminUser`,
  updatePassword:`${api_base_url}admin/resetPassword`,
  stateList: `${api_base_url}common/listAllState`,
};
export default apiConfig;
