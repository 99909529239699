import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import CustomPhoneInput from "../../../Components/CustomPhoneInput/CustomPhoneInput";
import {

  ENTER_EMAIL,
  FIRST_NAME_REQUIRED,
  INVALID_MOBILE_NO,
  LAST_NAME_REQUIRED,
  MOBILE_NO_REQUIRED,
} from "../../../lib/ValidationMessage";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useInfo } from "../../../context/UserContext";
const UpdateProfile = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
const info=useInfo()
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  
  useEffect(() => {
    info.getUserInfo();
  }, []);

  const handleConfirm = async (values, { resetForm }) => {
    info.updateAction(values);
  };

  const formik = useFormik({
    initialValues: {
      email: info.user?.email || "",
      first_name: info.user?.first_name || "",
      last_name: info.user?.last_name || "",
      phone_number: String(info.user?.phone_number) || "",
      is_superadmin: info.user?.is_superadmin || "0",
      department:info.user?.department || "",
      status: info.user?.status || "1",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email(ENTER_EMAIL).required(ENTER_EMAIL),
      first_name: Yup.string().required(FIRST_NAME_REQUIRED)
      .matches(
        "^[a-zA-Z']+[a-zA-Z'\\. ]*$",
        ("First Name must be valid")
      ),
      last_name: Yup.string().required(LAST_NAME_REQUIRED)
      .matches(
        "^[a-zA-Z']+[a-zA-Z'\\. ]*$",
        "Last Name must be valid"
      ),
      phone_number: Yup.string()
        .required(MOBILE_NO_REQUIRED)
        .max(10, INVALID_MOBILE_NO)
        .min(10, INVALID_MOBILE_NO),
    }),
    onSubmit: handleConfirm,
  });
  return (
    <>
      <Box
        className="update-profile-form"
        noValidate
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2} className="login-main-form">
          <Grid
            item
            xs={12}
            md={6}
            className="login-form-content login-space-divider"
          >
            <TextField
              variant="outlined"
              className="main-login-form"
              fullWidth
              name="email"
              label="Email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="login-form-content login-space-divider"
          >
            <TextField
              className="main-login-form"
              variant="outlined"
              fullWidth
              name="first_name"
              label="First Name"
              placeholder="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="login-form-content login-space-divider"
          >
            <TextField
              className="main-login-form"
              variant="outlined"
              fullWidth
              name="last_name"
              label="Last Name"
              placeholder="Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="login-form-content login-space-divider"
          >
            <CustomPhoneInput
              label="Mobile"
              id="phone_number"
              name="phone_number"
              className="main-login-form"
              fullWidth
              placeholder="Mobile Number"
              native={true}
              
              value={formik.values.phone_number}
              defaultCountry={`in`}
              country={`in`}
              divStyle={{
                paddingTop: 4,
              }}
              variant="outlined"
              onChange={(newValue, info) => {
                formik.setFieldValue("phone_number", newValue);
              }}
              onlyCountries={["in"]}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
              }
            />
          </Grid>
        </Grid>
        <Box style={{ display: "flex", justifyContent: "end", marginTop: 15 }}>
          <Button
            className="update-button"
            type="submit"
            variant="blue"
            sx={{
              borderRadius: 3,
            }}
          >
            {showLoader ? 'Loading' :'Submit'}
          </Button>
          <Button
            className="update-button"
            variant="blue"
            sx={{
              backgroundColor: "#F3F2F7",
              color: "#464255",
              borderRadius: 3,
              "&:hover": {
                backgroundColor: "#F3F2F7 !important",
              },
            }}
            onClick={() => {
              formik.resetForm({
                email: "",
                password: "",
                first_name: "",
                last_name: "",
              });
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </Box>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};

export default UpdateProfile;
