export const STATUS_200 = 200;
export const STATUS_300 = 300;
export const STATUS_400 = 400;
export const STATUS_401 = 401;
export const DATA_LIMIT = 10;
export const SESSION_CREATED = 'Session Created successfully';
export const SESSION_UPDATED = 'Session updated successfully';
export const SESSION_REPLICATED = 'Duplcate seminar created successfully';
export const SESSION_DELETED = 'Seminar deleted successfully';
export const SESSION_REJECTED ='Enquiry rejected successfully';
export const SEATS_UPDATED = 'Seminar seats updated successfully';
export const FETCH_FAIL = 'Failed to fetch';
export const LIMIT = (value=10)=>{return value};