import { Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <Typography marginTop={5} className="underconstruction-text">
      Analysis panel is currently underconstruction.
    </Typography>
  );
};

export default Dashboard;
