import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@mui/material";
import { adminPanelImages } from "../../Assets/Images";
import DashboardLogo from "../DashboardLogo/dashboardLogo";
import PersonIcon from "@mui/icons-material/Person";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { ReactComponent as LockIcon } from "../../Assets/Svg/lock.svg";
import './loginPage.css';
import { EMAIL_REQUIRED, PASSWORD_REQUIRED, VALID_EMAIL, email_required, password_required, valid_email } from "../../lib/ValidationMessage";
const LoginPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (auth.token) {
      navigate("/seminars");
    }
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    setPasswordType(() => (passwordType === "text" ? "password" : "text"));
  };

  //Validate login.
  const handleLogin = async () => {
    // const response = await LoginService(formik.values.emailAddress, formik.values.password);
    auth.loginAction(formik.values.emailAddress, formik.values.password);
  };

  //Formik and its properties for login form validation.
  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string()
        .trim()
        .email(VALID_EMAIL)
        .required(EMAIL_REQUIRED),
      password: Yup.string().trim().required(PASSWORD_REQUIRED),
    }),
    enableReinitialize: true,
    onSubmit: handleLogin,
  });
  return (
    <>
      <ToastContainer />
      <Box
        display={"flex"}
        height={"100vh"}
        flexDirection={"column"}
        sx={{
          backgroundImage: `url(${adminPanelImages.loginPageBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Box
          paddingTop={10}
          paddingLeft={10}
          textAlign={"left"}
          height={"10vh"}
        >
          <DashboardLogo source={adminPanelImages.dashboardLogoWhite} />
        </Box>
        <Grid
          container
          height={"90vh"}
          alignSelf={"center"}
          alignItems={"center"}
          paddingLeft={20}
        >
          <Grid item lg={4}>
            <Typography
              variant="h1"
              component={"h1"}
              sx={{ fontSize: 34, fontWeight: 600, lineHeight: "40px" }}
              textAlign={"left"}
              color="#fff"
            >
              Log In
            </Typography>
            <Typography
              variant="p"
              component={"p"}
              textAlign={"left"}
              marginTop={3}
              color="#fff"
              sx={{ fontSize: 18, fontWeight: 600, lineHeight: "21px" }}
            >
              Welcome! Please enter your email and password.
            </Typography>
            <Box className="login-form"
              marginTop={4}
              component={"form"}
              onSubmit={formik.handleSubmit}
              textAlign={"left"}
              noValidate
            >
              {/* <InputLabel variant="labelType1">Username</InputLabel> */}
              <TextField
                id="emailAddress"
                name="emailAddress"
                type="email"
                fullWidth
                margin="dense"
                placeholder="Username"
                sx={{
                  color: "#fff",
                  borderColor: "#fff",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon fontSize="large" htmlColor="#fff" />
                    </InputAdornment>
                  ),
                }}
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                error={
                  formik.touched.emailAddress &&
                  Boolean(formik.errors.emailAddress)
                }
                helperText={
                  formik.touched.emailAddress && formik.errors.emailAddress
                }
                className="textFieldType1"
              />
              <TextField
                id="password"
                name="password"
                className="textFieldType1"
                fullWidth
                placeholder="Password"
                margin="dense"
                sx={{
                  color: "#fff",
                }}
                type={passwordType}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" >
                      <IconButton sx={{margin:'0 -7px'}}>
                       <LockIcon/>
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment:(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="large" htmlColor="#fff" />
                        ) : (
                          <Visibility fontSize="large" htmlColor="#fff" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                 
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  Boolean(formik.errors.password) && formik.touched.password
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              <Button variant="lightBlue" fullWidth type="submit">
                {" "}
                Log In
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LoginPage;
