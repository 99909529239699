import apiConfig from "../configurations/ApiConfig";
export const CreateSeminarService = async (payload) => {
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.createSeminar}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};

export const fetchSeminarsService = async (payload) => {
  
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.listSeminar}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};

export const deleteSeminarsService = async (seminar_id) => {
  let token = localStorage.getItem("site");
  let payload = {
    seminar_id: seminar_id,
  };
  try {
    const response = await fetch(`${apiConfig.deleteSeminar}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const rejectEnquiryService = async (enquiryId) => {
  let token = localStorage.getItem("site");
  let payload = {
    user_id: enquiryId,
    status: "2",
  };
  try {
    const response = await fetch(`${apiConfig.rejectEnquiry}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const updateSeminarService = async (payload) => {
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.updateSeminar}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const duplicateSeminarService = async (payload) => {
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.replicateSeminar}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};

export const seminarUserListService = async (payload) => {
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.seminarUserList}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const exportSeminarUserListService = async (searchTerm,searchByGender, searchByState) => {
  const payload={};
  let token = localStorage.getItem("site");
  if(searchTerm){
    payload.search=searchTerm
  }
  if(searchByGender){
    payload.gender=searchByGender;
  }
  if(searchByState){
    payload.state=searchByState;
  }
  try {
    const response = await fetch(`${apiConfig.seminarUserList}`, {
      method: "POST",
      responseType: "blob",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.blob();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const manageSeminarSeatsService = async (payload) => {
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.manageSeats}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const getRegisterEnquiryData = async (payload) => {
  // let payload = {
  //   page: page,
  //   limit: limit
  // };
  // if(searchTerm){
  //   payload.search=searchTerm
  // }
  // if(searchBy){
  //   payload.search_by=searchBy
  // }
 
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.regEnquiry}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const exportRegisterEnquiryData = async (searchTerm,searchByGender,searchByState,ageGroup) => {
const payload={};
  let token = localStorage.getItem("site");
  if(searchTerm){
    payload.search=searchTerm
  }
  if(searchByGender){
    payload.gender=searchByGender;
  }
  if(searchByState){
    payload.state=searchByState;
  }
  if(ageGroup){
    payload.age_group=ageGroup;
  }
  try {
    const response = await fetch(`${apiConfig.regEnquiry}`, {
      method: "POST",
      responseType:'blob',
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.blob();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const assignSeminarToUsersService = async (payload) => {
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.addSeminarToUsers}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const getUserDetails = async () => {
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.userDetails}`, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const UpdateUserDetails = async (payload) => {
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.updateUserDetails}`, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const UpdateAdminPasswordService = async (payload) => {
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.updatePassword}`, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const getCmsDataService = async (data) => {
 try {
    const response = await fetch(`${apiConfig.cmsData}?pagename=${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const getBannersImageService = async () => {
  try {
     const response = await fetch(`${apiConfig.bannerImage}`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(),
     });
     const responseBody = await response.json();
     return responseBody;
   } catch (e) {
     return e.message;
   }
 };
export const updateCmsDataService = async (payload) => {
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.updatecmsData}`, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const uploadCmsImageService = async (payload) => {
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.uploadImage}`,{
      method: 'POST',
      headers: {
        Authorization: `${token}`,
       
      },
      body: payload,
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const uploadBannerImageService = async (payload) => {
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.uploadBannerImage}`,{
      method: 'POST',
      headers: {
        Authorization: `${token}`,
       
      },
      body: payload,
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const removeBannerImageService = async (payload) => {
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.removeBannerImage}`,{
      method: 'DELETE',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const seminarReportListService = async (payload) => {
  
  let token = localStorage.getItem('site');
  try {
    const response = await fetch(`${apiConfig.reportList}`, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
}
export const fetchAllVideosService = async (payload) => {
  
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.videoList}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const insertNewVideosService = async (payload) => {
  
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.insertVideo}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const deleteVideosFromListService = async (payload) => {
  
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.deleteVideo}`, {
      method: "DELETE",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const publishVideosFromListService = async (payload) => {
  
  let token = localStorage.getItem("site");
  try {
    const response = await fetch(`${apiConfig.publishVideo}`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (e) {
    return e.message;
  }
};
export const getStateService = async () => {
  try {
     const response = await fetch(`${apiConfig.stateList}`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(),
     });
     const responseBody = await response.json();
     return responseBody;
   } catch (e) {
     return e.message;
   }
 };