const range = (start, end) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
};
 
export const DOTS = '...';
 
export const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 0,
    currentPage
}) => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
 
    /*
        Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
 
    const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
    );
          let middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return [...middleRange, totalPageCount];
};