import React from "react";
import { Box, Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateSeminarService } from "../../Services/services";
import { STATUS_200, FETCH_FAIL, SESSION_UPDATED } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./seminarModule.css";
import { AGE_GROUP_REQUIRED, CITY_REQUIRED, DESCRIPTION_REQUIRED, DURATION_REQUIRED, LESS_THAN_24HRS_VALIDATION, LEVEL_REQUIRED, MIN_1_SEAT_REQUIRED, MORE_THAN_1_VALIDATION, NUMBER_REQUIRED, SEMINARNAME_3_CHARACTERS_REQUIRED, SEMINARNAME_REQUIRED, SEMINAR_DATE_CANNOT_BE_LESS_THAN_CURRENT_DATE, SEMINAR_DATE_REQUIRED, SEMINAR_SCHEDULE_REQUIRED, SEMINAR_SEATS_REQUIRED, SEMINAR_TIME_REQUIRED, SEMINAR_UPDATED_SUCCESSFULLY, SEMINAR_VENUE_REQUIRED, SUBMIT 
  } from "../../lib/ValidationMessage";
import { seminarLevels } from "../../lib/helper";
import { cities } from "../../lib/helper";
import { ageGroups } from "../../lib/helper";
import moment from "moment/moment";
const EditSeminar = ({
  data,
  editSeminarDialog,
  fetchSeminarList,
  setEditSeminarDialog,
}) => {
  const current_date=new Date();
  const [seminarData, setSeminarData] = useState(data);
  const [showLoader, setShowLoader] = useState(false);
  const [maxCharacter, setMaxCharacter] = useState(1000);
  const [seminarDialogDtl, setSeminarDialogDtl] = useState({
    title: "Edit Seminar",
    subTitle: "Edit seminar details below.",
    task: "edit",
  });

  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };

  const hideCSModal = () => {
    setEditSeminarDialog(false);
  };

  const updateSeminar = async () => {
    let payload = {
      seminar_id: data.seminarId,
      name: formik.values.seminarName,
      // age: formik.values.ageGroup,
      city: formik.values.seminarCity,
      // level: formik.values.seminarLevel,
      date: formik.values.seminarDate,
      time: formik.values.seminarTime,
      seat: formik.values.seminarSeats,
      venue: formik.values.seminarVenue,
      duration: formik.values.seminarDuration,
      // schedule: formik.values.seminarSchedule,
      description: formik.values.seminarDescription,
    };
    showLoaderHandler();
    let response = await updateSeminarService(payload);
    if (response.status === STATUS_200) {
        toast.success(SEMINAR_UPDATED_SUCCESSFULLY);
        setEditSeminarDialog(false);
        hideLoaderHandler();
        formik.resetForm();
        fetchSeminarList(1);  
    } 
    
    else {
      toast.error(response?.message);
      setEditSeminarDialog(false);
      hideLoaderHandler();
    }
  };

  useEffect(() => {
    editSeminar();
  }, []);
  const editSeminar = () => {
    formik.setValues({
      seminarName: seminarData.seminarName,
      // ageGroup: seminarData.ageGroup,
      seminarCity: seminarData.seminarCity,
      // seminarLevel: seminarData.seminarLevel,
      seminarDate: seminarData.seminarDate,
      seminarTime: seminarData.seminarTime,
      seminarSeats: seminarData.numOfSeats,
      seminarVenue: seminarData.seminarVenue,
      seminarDuration: seminarData.seminarDuration.match(/\d+/)[0],
      // seminarSchedule: seminarData.seminarSchedule,
      seminarDescription: seminarData.seminarDescription,
    });
  };
  const formik = useFormik({
    initialValues: {
      seminarName: "",
      // ageGroup: "",
      seminarCity: "",
      // seminarLevel: "",
      seminarDate: "",
      seminarTime: "",
      seminarSeats: "",
      seminarVenue: "",
      seminarDuration: "",
      // seminarSchedule: "",
      seminarDescription: "",
    },
    validationSchema: Yup.object({
      seminarName: Yup.string()
        .min(3, SEMINARNAME_3_CHARACTERS_REQUIRED)
        .required(SEMINARNAME_REQUIRED),
      // ageGroup: Yup.string().required(AGE_GROUP_REQUIRED),
      seminarCity: Yup.string().required(CITY_REQUIRED),
      // seminarLevel: Yup.string().required(LEVEL_REQUIRED),
      seminarDate: Yup.date()
        .min(
          new Date(),
          SEMINAR_DATE_CANNOT_BE_LESS_THAN_CURRENT_DATE
        )
        .required(SEMINAR_DATE_REQUIRED),
      seminarTime: Yup.string().required(
        SEMINAR_TIME_REQUIRED
      ),
      seminarSeats: Yup.number()
        .min(1, MIN_1_SEAT_REQUIRED)
        .required(SEMINAR_SEATS_REQUIRED),
      seminarVenue: Yup.string()
        .min(3, SEMINARNAME_3_CHARACTERS_REQUIRED)
        .required(SEMINAR_VENUE_REQUIRED),
      seminarDuration: Yup.number(NUMBER_REQUIRED)
        .required(DURATION_REQUIRED)
        .min(1, MORE_THAN_1_VALIDATION)
        .lessThan(25, LESS_THAN_24HRS_VALIDATION),
      // seminarSchedule: Yup.string().required(
        // SEMINAR_SCHEDULE_REQUIRED
      // ),
      seminarDescription: Yup.string()
        .min(3, SEMINARNAME_3_CHARACTERS_REQUIRED)
        .required(DESCRIPTION_REQUIRED),
    }),
    onSubmit: updateSeminar,
  });

  return (
    <>
      <CustomDialog
        handleOpen={editSeminarDialog}
        title={seminarDialogDtl.title}
        subTitle={seminarDialogDtl.subTitle}
        fullWidth={true}
        maxWidth={"md"}
        showClose={true}
        handleClose={hideCSModal}
      >
        <Box component={"form"} onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item lg={6}>
              <TextField
                fullWidth
                id="seminarName"
                name="seminarName"
                label="Seminar Name"
                placeholder="Seminar name"
                value={formik.values.seminarName}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarName &&
                  Boolean(formik.errors.seminarName)
                }
                helperText={
                  formik.touched.seminarName && formik.errors.seminarName
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              />
            </Grid>
            {/* <Grid item lg={4}>
              <TextField
                fullWidth
                id="ageGroup"
                name="ageGroup"
                select
                label="Age Group"
                placeholder="Select Age Group"
                value={formik.values.ageGroup}
                onChange={formik.handleChange}
                error={
                  formik.touched.ageGroup && Boolean(formik.errors.ageGroup)
                }
                helperText={formik.touched.ageGroup && formik.errors.ageGroup}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              >
                {ageGroups.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item lg={6}>
              <TextField
                fullWidth
                id="seminarCity"
                name="seminarCity"
                label="City"
                placeholder="Seminar city "
                select
                value={formik.values.seminarCity}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarCity &&
                  Boolean(formik.errors.seminarCity)
                }
                helperText={
                  formik.touched.seminarCity && formik.errors.seminarCity
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              >
                {cities.sort((a,b) => a.label > b.label ? 1 : -1).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
           
            <Grid item lg={4}>
              <TextField
                fullWidth
                id="seminarDate"
                name="seminarDate"
                label="Seminar Date"
                placeholder="Seminar date"
                value={formik.values.seminarDate}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarDate &&
                  Boolean(formik.errors.seminarDate)
                }
                helperText={
                  formik.touched.seminarDate && formik.errors.seminarDate
                }
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps:{
                  min:moment(current_date).format('YYYY-MM-DD') 
                },
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                fullWidth
                id="seminarTime"
                name="seminarTime"
                label="Seminar Time"
                placeholder="Seminar time"
                value={formik.values.seminarTime}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarTime &&
                  Boolean(formik.errors.seminarTime)
                }
                helperText={
                  formik.touched.seminarTime && formik.errors.seminarTime
                }
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                fullWidth
                id="seminarSeats"
                name="seminarSeats"
                label="No. Of Seats"
                placeholder="Number of seats"
                value={formik.values.seminarSeats}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarSeats &&
                  Boolean(formik.errors.seminarSeats)
                }
                helperText={
                  formik.touched.seminarSeats && formik.errors.seminarSeats
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                fullWidth
                id="seminarVenue"
                name="seminarVenue"
                label="Seminar Venue"
                placeholder="Seminar venue"
                value={formik.values.seminarVenue}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarVenue &&
                  Boolean(formik.errors.seminarVenue)
                }
                helperText={
                  formik.touched.seminarVenue && formik.errors.seminarVenue
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                fullWidth
                id="seminarDuration"
                name="seminarDuration"
                label="Seminar Duration"
                placeholder="Seminar duration"
                value={formik.values.seminarDuration}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarDuration &&
                  Boolean(formik.errors.seminarDuration)
                }
                helperText={
                  formik.touched.seminarDuration &&
                  formik.errors.seminarDuration
                }
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 0 } ,
                  sx: {
                    borderRadius: 5,
                  },
                  endAdornment: "Hour(s)",
                }}
                required
              />
            </Grid>
           
            <Grid item lg={12}>
              <TextField
                fullWidth
                id="seminarDescription"
                name="seminarDescription"
                label="Seminar Description"
                placeholder="Type your Description"
                value={formik.values.seminarDescription}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminarDescription &&
                  Boolean(formik.errors.seminarDescription)
                }
                helperText={
                  formik.touched.seminarDescription &&
                  formik.errors.seminarDescription
                }
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ maxLength: maxCharacter }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              />
               <Typography component={'small'} sx={{fontSize:'12px', marginLeft:'8px'}}>{formik.values.seminarDescription.length} {" "}out of {" "}{maxCharacter} characters</Typography>

            </Grid>
            <Grid item lg={12}>
              <Button
                variant="blue"
                type="submit"
                size="large"
                sx={{
                  borderRadius: 3,
                }}
              >
               {SUBMIT}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </>
  );
};

export default EditSeminar;
