import {
    Box,
    Button,
    Typography,
  } from "@mui/material";
  import {useState } from "react";
  import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
  import {
    deleteSeminarsService,
  } from "../../Services/services";
  import {
    STATUS_200,
    FETCH_FAIL,
    SESSION_DELETED,
  } from "../../data/constants";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import "./seminarModule.css";
  import {SEMINAR_DELETED_SUCCESSFULLY, WANT_TO_REMOVE, OK, CANCEL } from "../../lib/ValidationMessage";
const DeleteSeminar=({data,setShowDeleteConfirmationDialog,fetchSeminarList})=> {
    const [showLoader, setShowLoader] = useState(false);
    const hideLoaderHandler = () => {
        setShowLoader(false);
      };
      const showLoaderHandler = () => {
        setShowLoader(true);
      };
     
      const closeDeleteCnfDialog=()=>{
        setShowDeleteConfirmationDialog({
            show:false,
            value:''
        })
    }
    const deleteSeminar = async () => {
        let seminar_id = data;
        showLoaderHandler();
        const response = await deleteSeminarsService(seminar_id);
        if (response.status === STATUS_200) {
          if (response?.message === SESSION_DELETED) {
            toast.success(SEMINAR_DELETED_SUCCESSFULLY);
            hideLoaderHandler();
            fetchSeminarList(1);
          } else {
            toast.error(response?.message);
            hideLoaderHandler();
          }
        } else if (response === FETCH_FAIL) {
            toast.error(response?.message);
          hideLoaderHandler();
         
        } else {
          toast.error(response?.message);
          hideLoaderHandler();
        }
      };
    
    return (
   <>
     <CustomDialog
            title="Delete Seminar"
            handleClose={closeDeleteCnfDialog}
            actions={
              <Box>
                <Button className="cancel-btn" onClick={closeDeleteCnfDialog}>{CANCEL}</Button>
                <Button className="confirm-btn"
                  onClick={() => {  setShowDeleteConfirmationDialog((prevState) => {
                      return { ...prevState, show: false };
                    });
                    deleteSeminar();}} 
                >
                 {OK}
                </Button>
              </Box>
            }
          >
            <Typography className="alert-text">
             {WANT_TO_REMOVE}
            </Typography>
          </CustomDialog>
   </>
  )
}

export default DeleteSeminar;