import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getBannersImageService
} from "../../Services/services";
import { STATUS_200, STATUS_401 } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { useRef } from "react";
import UploadImage from "./UploadImage";
import { ReactComponent as DeleteIcon } from "../../Assets/Svg/delete.svg";
import DeleteBannerImage from "./DeleteBannerImage";
const BannerImages = () => {
  const [openBannerUploadDialog, setOpenBannerUploadDialog] = useState(false);
  const [deleteBannerDialog, setDeleteBannerDialog] = useState(false);
  const [deleteImageUrl, setDeleteImageUrl] = useState();
  const [bannerImage, setBannerImage] = useState([]);
  const [selectedImageData, setSelectedImageData] = useState();
  const [imageCount, setImageCount]=useState();
  const imageInputRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    getCmsBannerImage();
  }, []);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const getCmsBannerImage = async () => {
    showLoaderHandler();
    const response = await getBannersImageService();
    if (response.status === STATUS_200) {
      setBannerImage(response?.data);
      setImageCount(response?.data?.length)
      
      hideLoaderHandler();
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };

  const removeBannerImage = (image_url) => {
    setDeleteBannerDialog(true);
    setDeleteImageUrl(image_url);
  };
const bannerUploadDialog=()=>{
  if( imageCount < 10){
    setOpenBannerUploadDialog(true)
  }
  else{
    if (!toast.isActive('warning-toast')) {
    toast.warning('Cannot upload more than 10 images',{toastId:'warning-toast'}) 
    }
  }
}
  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={2} md={2} lg={2}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "1.334",
              letterSpacing: "0em",
              margin: "16px 0",
            }}
          >
            Banner Image(s)
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3} textAlign={"end"} className="save-btn">
          <Button
            variant="blue"
            onClick={bannerUploadDialog}
          >
            Upload New Image
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {bannerImage.map((data, index) => {
         
          const url = `https://d3nn873nee648n.cloudfront.net/smmobile/banner_image/${data?.image_name}`;
          return (
            <React.Fragment key={index}>
              <Grid item xs={3} md={4} lg={4} className="banner-image-list-container">
                <Box component={"div"} className="banner-image-list">
                  <img
                    src={url}
                    alt={`Banner ${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                  <Box component={"div"} className="banner-image-list-text">
                   
                 
                
               </Box>
                  <Box component={"div"} className="banner-image-list-icon" onClick={()=>{removeBannerImage(data?.image_name)}}>
                   
                      <DeleteIcon />
                   
                  </Box>
                </Box>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      {openBannerUploadDialog && (
        <UploadImage
          openBannerUploadDialog={openBannerUploadDialog}
          setOpenBannerUploadDialog={setOpenBannerUploadDialog}
          getBannerImages={getCmsBannerImage}
          imageCount={imageCount}
        />
      )}
      {deleteBannerDialog && (
        <DeleteBannerImage
          deleteBannerDialog={deleteBannerDialog}
          setDeleteBannerDialog={setDeleteBannerDialog}
          bannerUrl={deleteImageUrl}
          getCmsBannerImage={getCmsBannerImage}
        />
      )}
      <ToastContainer containerId={'banner-image'}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: 1400 }}
        open={showLoader}
        onClick={hideLoaderHandler}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BannerImages;
