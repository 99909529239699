import React, { useEffect, useState } from "react";
import TextEditor from "../../Components/Common/TextEditor";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CANNOT_BE_BLANK,
  CONTENT_UPDATED_SUCCESSFULLY,
  THIS_FIELD_IS_REQUIRED,
} from "../../lib/ValidationMessage";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import {
  getCmsDataService,
  updateCmsDataService,
} from "../../Services/services";
import { STATUS_200, STATUS_401 } from "../../data/constants";
import "./cms.css";
function ThePerson() {
  const [showLoader, setShowLoader] = useState(false);
  const [maxCharacter, setMaxCharacter] = useState(3000);
  const [personContent, setPersonContent] = useState({
    parent_key: "",
    key_name: "",
    key_value: "",
  });
  useEffect(() => {
    getThePersonContent();
  }, []);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };

  const getThePersonContent = async () => {
    showLoaderHandler();
    const response = await getCmsDataService("the_person");
    if (response.status === STATUS_200) {
      setPersonContent({
        parent_key: response?.data[0]?.parent_key,
        key_name: response?.data[0]?.key_name,
        key_value: response?.data[0]?.key_value,
      });

      hideLoaderHandler();
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };

  const handleConfirm = async (values, { resetForm }) => {
    showLoaderHandler();
    if (values.description) {
      let newString = values.description;
      newString = newString.split("<strong");
      
      if (newString?.length) {
        
        newString = newString.join(' <strong style="margin: 0px; font-style: italic;color: #D64A00;  font-size: 26px; font-weight: 400; line-height: 30px" ');
       
      } else {
        newString = values.description;
      }
      newString = newString.split("<p");
      if (newString?.length) {
        //font-family: Allura; font-size: 26px; font-weight: 400; line-height: 16px;
        newString = newString.join(' <p style="margin: 0px; font-style: italic; color:#212121;font-family: Poppins; font-size: 16px ;font-weight: 600; line-height: 24px; "');
      } else {
        newString = values.description;
      }

      newString = newString.split("<br");
      if (newString?.length) {
        newString = newString.join(
          ` <br style="display: block; content: ''; padding: 5px;" `
        );
      } else {
        newString = values.description;
      }

      newString = newString.split("<a");
      if (newString?.length) {
        newString = newString.join(
          ' <a class="table-vertical-bluetext-technical" '
        );
      } else {
        newString = values.description;
      }

      let payload = {
        parent_key: personContent?.parent_key,
        key_name: personContent?.key_name,
        key_value: newString,
      };

      let newObj = { ...payload };
      showLoaderHandler();
      let response = await updateCmsDataService(newObj);
      if (response.status === STATUS_200) {
        hideLoaderHandler();
       
          toast.success(CONTENT_UPDATED_SUCCESSFULLY, {
            toastId: "success-toast",
          });
        
        getThePersonContent();
      } else {
        if (!toast.isActive("error-toast")) {
          toast.error(response?.message, { toastId: "error-toast" });
        }
        hideLoaderHandler();
      }
      
    }
  };
  const formik = useFormik({
    initialValues: {
      description: personContent?.key_value,
    },
    validationSchema: Yup.object({
      description: Yup.string().required(THIS_FIELD_IS_REQUIRED)

    }),
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  const handleChange = (content) => {
    if (content == "<p><br></p>") {
      formik.setFieldValue("description", "");
    } else {
      formik.setFieldValue("description", content);
    }
  };

  return (
    <>
      <Box
        className="person-pagge-container"
        noValidate
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              style={{ marginBottom: 4, paddingLeft: 3 }}
            >
              The Person
            </Typography>
            <TextEditor
              content={formik.values.description}
              handleChange={handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              modules={{
                toolbar: [
                  ["link"],
                  [{ color: ["red", "#785412", "#000", "#D64A00"] }],
                  [{ background: ["red", "#fff", "#0000", "#785412"] }],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { align: ["right", "center", "justify"] },
                  ],
                  // [{ 'indent': '-1'}, { 'indent': '+1' }],
                  ["bold","italic", "underline"],
                ],
              }}
              label="Description"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ marginTop: "16px", textAlign: "right" }}
          >
            <Button type="submit" variant="blue">
              {" "}
              Submit
            </Button>
            <Button variant="grey"  onClick={() => {
              formik.resetForm({
                subject: '',
              })}}>Cancel</Button>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default ThePerson;
