import MainLayout from '../../Layouts/MainLayout';
import { WELCOME_TO_ADMIN_DASHBOARD } from '../../lib/ValidationMessage';
import SeminarUsers from './SeminarUsers';
const SeminarUsersContainer = () => {
  return (
    <MainLayout
      panelTitle={'Seminar'}
      panelSubTitle={WELCOME_TO_ADMIN_DASHBOARD}
    >
      <SeminarUsers />
    </MainLayout>
  );
};

export default SeminarUsersContainer;
