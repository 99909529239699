// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidebarNavlink__GU24b{
    display: inline-flex;
    width: 100%;
    padding: 16px 8px;
    border-radius: 18px;
    cursor: pointer;
    color: #BCBEBE;
    font-size: 0.875rem;
    justify-content: flex-start;
    font-family:'Poppins', sans-serif;
    -webkit-user-select: none;
            user-select: none;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0; 
    text-decoration: none;  
}
.sidebar_sidebarNavlink__GU24b:hover{
    background-color: rgba(0, 0, 0, 0.04);
}

.sidebar_sidebarActieLink__XGQvn{
    background-color: rgba(0, 0, 0, 0.04);
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,2BAA2B;IAC3B,iCAAiC;IACjC,yBAAiB;YAAjB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;IACtB,6BAA6B;IAC7B,UAAU;IACV,SAAS;IACT,SAAS;IACT,qBAAqB;AACzB;AACA;IACI,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;IACrC,WAAW;AACf","sourcesContent":[".sidebarNavlink{\n    display: inline-flex;\n    width: 100%;\n    padding: 16px 8px;\n    border-radius: 18px;\n    cursor: pointer;\n    color: #BCBEBE;\n    font-size: 0.875rem;\n    justify-content: flex-start;\n    font-family:'Poppins', sans-serif;\n    user-select: none;\n    align-items: center;\n    position: relative;\n    box-sizing: border-box;\n    background-color: transparent;\n    outline: 0;\n    border: 0;\n    margin: 0; \n    text-decoration: none;  \n}\n.sidebarNavlink:hover{\n    background-color: rgba(0, 0, 0, 0.04);\n}\n\n.sidebarActieLink{\n    background-color: rgba(0, 0, 0, 0.04);\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarNavlink": `sidebar_sidebarNavlink__GU24b`,
	"sidebarActieLink": `sidebar_sidebarActieLink__XGQvn`
};
export default ___CSS_LOADER_EXPORT___;
