import React, { useEffect, useState}  from "react";
import { useContext } from "react";
import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { closingTime, errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_401 } from "../data/constants";
import { getUserDetails, UpdateUserDetails } from "../Services/services";
import { DETAILS_UPDATED_SUCCESSFULLY } from "../lib/ValidationMessage";
const UserContext= React.createContext();

const UserContextProvider =({children})=>{
const [user, setUser]=useState(null);
const [showLoader, setShowLoader] = useState(false);
// useEffect(()=>{
//   getUserInfo();
// },[])
const showLoaderHandler = () => {
  setShowLoader(true);
};
const hideLoaderHandler = () => {
  setShowLoader(false);
};
const getUserInfo = async () => {
   showLoaderHandler();
  const response = await getUserDetails();
  if (response.status === STATUS_200) {
    setUser(response?.data);
    hideLoaderHandler();
  } else if (response.status === STATUS_401) {
    window.location.href = "/";
    localStorage.clear();
    toast.error(response?.message,{toastId:'info-error'});
  } else {
    toast.error(response?.message,{toastId:'info-error'});
    hideLoaderHandler();
  }
};
const updateAction = async (values) => {
    try {
const response = await UpdateUserDetails(values);
if (response.status === STATUS_200) {
  toast.success(DETAILS_UPDATED_SUCCESSFULLY,{toastId:'update-profile-success'});
getUserInfo();
} 
if (response.status === STATUS_401) {
  window.location.href = "/";
  localStorage.clear();
  toast.error(response?.message);
}
}  catch (error) {
    toast.error(errorMessage(error), {
      autoClose: closingTime,
    });
}
}
return (
    <UserContext.Provider value={{user, setUser, getUserInfo ,updateAction}}>{children}</UserContext.Provider>
)
}
export default UserContextProvider;

export const useInfo = () => {
    return useContext(UserContext);
  };