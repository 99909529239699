import { IconButton, InputAdornment } from "@mui/material";
import React, {  useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import "../../ProfileSetting/profile.css";
import {
  ENTER_PASSWORD,
  PASSWORD_MUST_MATCH,
  PASSWORD_UPDATED_SUCCESSFULLY,
  PWD_CONTAIN_SPECIAL_CHARACTER_VALIDATION,
  PWD_MAX_VALIDATION,
  PWD_MIN_VALIDATION,
} from "../../../lib/ValidationMessage";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { STATUS_200, STATUS_401 } from "../../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateAdminPasswordService } from "../../../Services/services";
const ManagePassword = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isoldPassVisible, setIsOldPassVisible] = useState(false);
  const [isConfirmPassVisible, setConfirmPassVisible] = useState(false);
  const navigate = useNavigate();
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const handleChangePwd = async (values, { resetForm }) => {
    showLoaderHandler(true);
    let resp = await UpdateAdminPasswordService(values);
    if (resp.status === STATUS_200) {
      toast.success(PASSWORD_UPDATED_SUCCESSFULLY);
      resetForm();
      navigate("/cms");
      hideLoaderHandler(false);
    } else if (resp.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(resp?.message);
      hideLoaderHandler(false);
    } else {
      toast.error(resp?.message);
      hideLoaderHandler(false);
    }
  };

  const handleMouseDownPassword = (e) => {};
  const handleClickShowPassword = () => {
    setIsPassVisible(!isPassVisible);
  };

  const handleMouseDownOldPassword = (e) => {};
  const handleClickShowOldPassword = () => {
    setIsOldPassVisible(!isoldPassVisible);
  };

  const handleMouseDownConfirmPassword = (e) => {};
  const handleClickShowConfirmPassword = () => {
    setConfirmPassVisible(!isConfirmPassVisible);
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      
      old_password: Yup.string().required(ENTER_PASSWORD),

     
      new_password: Yup.string()
      .required(ENTER_PASSWORD)
      .max(16, PWD_MAX_VALIDATION)
      .min(8, PWD_MIN_VALIDATION)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
PWD_CONTAIN_SPECIAL_CHARACTER_VALIDATION      ),
      confirm_password: Yup.string()
        .required(ENTER_PASSWORD)
        .oneOf([Yup.ref("new_password")], PASSWORD_MUST_MATCH),
    }),
    onSubmit: handleChangePwd,
  });

  return (
    <>
      <Box
        noValidate
        component="form"
        className="update-password-form"
        onSubmit={formik.handleSubmit}
      >
        <Box
          component={"div"}
          className="login-main-form"
          style={{ width: "630px" }}
        >
          <Box
            component={"div"}
            className="login-form-content login-space-divider"
          >
            <TextField
              label="Old Password"
              className="main-login-form"
              fullWidth
              id="old_password"
              name="old_password"
              type={isPassVisible ? "text" : "password"}
              placeholder="Old Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon className="loginform-field-icons" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {isPassVisible ? (
                        <VisibilityOff className="loginform-field-icons login-eye-icon" />
                      ) : (
                        <Visibility className="loginform-field-icons login-eye-icon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("old_password", e.target.value);
              }}
              error={
                formik.touched.old_password &&
                Boolean(formik.errors.old_password)
              }
              helperText={
                formik.touched.old_password && formik.errors.old_password
              }
            />
          </Box>
          <Box
            component={"div"}
            className="login-form-content login-space-divider"
          >
            <TextField
              label="New Password"
              className="main-login-form"
              fullWidth
              id="new_password"
              name="new_password"
              type={isoldPassVisible ? "text" : "password"}
              placeholder="New Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon className="loginform-field-icons" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownOldPassword}
                    >
                      {isoldPassVisible ? (
                        <VisibilityOff className="loginform-field-icons login-eye-icon" />
                      ) : (
                        <Visibility className="loginform-field-icons login-eye-icon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.new_password}
              onChange={(e) => {
                formik.setFieldValue("new_password", e.target.value);
              }}
              error={
                formik.touched.new_password &&
                Boolean(formik.errors.new_password)
              }
              helperText={
                formik.touched.new_password && formik.errors.new_password
              }
            />
          </Box>
          <Box
            component={"div"}
            className="login-form-content login-space-divider"
          >
            <TextField
              label=" Confirm Password"
              className="main-login-form"
              fullWidth
              id="confirm_password"
              name="confirm_password"
              type={isConfirmPassVisible ? "text" : "password"}
              placeholder=" Confirm Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon className="loginform-field-icons" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {isConfirmPassVisible ? (
                        <VisibilityOff className="loginform-field-icons login-eye-icon" />
                      ) : (
                        <Visibility className="loginform-field-icons login-eye-icon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.confirm_password}
              onChange={(e) => {
                formik.setFieldValue("confirm_password", e.target.value);
              }}
              error={
                formik.touched.confirm_password &&
                Boolean(formik.errors.confirm_password)
              }
              helperText={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              }
            />
          </Box>
        </Box>

        <Box
          component={"div"}
          style={{ display: "flex", justifyContent: "end", marginTop: 15 }}
        >
          <Button
            className="update-button"
            variant="blue"
            type="submit"
            sx={{
              borderRadius: 3,
            }}
          >
            { showLoader? 'Loading':'Submit'}
          </Button>
          <Button
            variant="blue"
            className="update-button"
            sx={{
              backgroundColor: "#F3F2F7",
              color: "#464255",
              borderRadius: 3,
              "&:hover": {
                backgroundColor: "#F3F2F7 !important",
              },
            }}
            onClick={() => {
              formik.resetForm({
                email: "",
                password: "",
                first_name: "",
                last_name: "",
              });
            }}
            disabled={showLoader}
          >
            Cancel
          </Button>
        </Box>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};

export default ManagePassword;
