// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ql-editor{
    min-height: 250px;
    /* line-height: 1.42; */
}
.ql-snow .ql-picker-options{
    background-color: #d9d9d9 !important;
}
/* strong{
    font-family: 'Allura' !important;
} */`, "",{"version":3,"sources":["webpack://./src/Components/Common/textEditor.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,uBAAuB;AAC3B;AACA;IACI,oCAAoC;AACxC;AACA;;GAEG","sourcesContent":["\n.ql-editor{\n    min-height: 250px;\n    /* line-height: 1.42; */\n}\n.ql-snow .ql-picker-options{\n    background-color: #d9d9d9 !important;\n}\n/* strong{\n    font-family: 'Allura' !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
