import React, { useRef } from "react";
import { IMAGE_UPDATED_SUCCESSFULLY } from "../../lib/ValidationMessage";
import {  Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import { useState } from "react";
import { uploadBannerImageService } from "../../Services/services";
import { STATUS_200 } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
const UploadImage=({
  openBannerUploadDialog,
  setOpenBannerUploadDialog,
  getBannerImages,
  imageCount
})=> {
  const imageInputRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [imageData, setImageData] = useState();
  const [selectedImageData, setSelectedImageData] = useState();
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const hideBannerUploadModal = () => {
    setOpenBannerUploadDialog(false);
  };
  const onBannerImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageData(URL.createObjectURL(event.target.files[0]));
      let fileName = event.target.files[0].name.split(".");
      fileName = fileName[fileName?.length - 1];
      let newFile = new File(
        [event.target.files[0]],
        `${"item"}.${fileName || "jpeg"}`,
        { type: event.target.files[0].type }
      );
      setSelectedImageData(newFile);
    }
  };
  
  const updateBannerImage = async () => {
   
    const formData = new FormData();
    formData.append("files", selectedImageData);
    formData.append("folder_name", "banner_image");

    showLoaderHandler();
    const response = await uploadBannerImageService(formData);
    if (response.status === STATUS_200) {
      toast.success(IMAGE_UPDATED_SUCCESSFULLY)
      hideLoaderHandler();
      hideBannerUploadModal();
      getBannerImages();
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  
      }
  return (
    <>
      <CustomDialog
        handleOpen={openBannerUploadDialog}
        title="Upload New Image"
        fullWidth={true}
        maxWidth={"sm"}
        showClose={true}
        handleClose={hideBannerUploadModal}
      >
        <Grid item lg={12}>
          <Button
            variant="grey"
            size="large"
            sx={{
              borderRadius: 3,
            }}
            component="label"
            htmlFor="upload-banner-img"
          >
            {imageData ? (
              <img
                id="bannerImg"
                src={imageData}
                alt="banner-imge"
                style={{ maxWidth: 500, borderRadius: "8px" }}
              />
            ) : (
              " Click Here"
            )}
          </Button>
          <input
            type="file"
            id="upload-banner-img"
            ref={imageInputRef}
            onChange={onBannerImageChange}
            hidden
          />
        </Grid>
        <Grid
          item
          lg={12}
          sx={{
            textAlign: "right", marginTop:2
          }}
        >
          <Button
            variant="blue"
            type="submit"
            size="large"
            sx={{
              borderRadius: 3,
            }}
            onClick={updateBannerImage}
          >
            Upload
          </Button>
          <Button
            variant="grey"
            onClick={hideBannerUploadModal}
            size="large"
            sx={{
              borderRadius: 3,
            }}
          >
            Cancel
          </Button>
        </Grid>
      </CustomDialog>
      
       
    </>
  );
}

export default UploadImage;
