import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { getCmsDataService, uploadCmsImageService } from "../../Services/services";
import { STATUS_200, STATUS_401 } from "../../data/constants";
import { IMAGE_UPDATED_SUCCESSFULLY } from "../../lib/ValidationMessage";
import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";

function HomepageImage() {
  const [homepageImageData, setHomepageImageData] = useState({
    parent_key: "",
    key_name: "",
  });
  const [imageData, setImageData] = useState();
  const [selectedImageData, setSelectedImageData] = useState();
  
  const imageInputRef = useRef(null);

  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    getCmsHomepageImage();
   
  }, []);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
 
  const getCmsHomepageImage = async () => {
    showLoaderHandler();
    const response = await getCmsDataService("homepage");
    if (response.status === STATUS_200) {
      setHomepageImageData({
        parent_key: response?.data[0]?.parent_key,
        key_name: response?.data[0]?.key_name,
      });
      setImageData(response?.data[0]?.key_value);
      hideLoaderHandler();
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };
 
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageData(URL.createObjectURL(event.target.files[0]));
      let fileName = event.target.files[0].name.split(".");
      fileName = fileName[fileName?.length - 1];
     
      let newFile = new File(
        [event.target.files[0]],
        `home_image.${fileName}`,
        { type: event.target.files[0].type }
      );
      setSelectedImageData(newFile);
    }
  };

  const updateHomepageImage = async () => {
    const formData = new FormData();
   
    if (selectedImageData) {
      formData.append("uploaded_file", selectedImageData);
      formData.append("folder_name", "home_image");
      showLoaderHandler();
      const response = await uploadCmsImageService(formData);
      if (response.status === STATUS_200) {
        if (!toast.isActive('success-toast')) {
          toast.success(IMAGE_UPDATED_SUCCESSFULLY, { toastId: 'success-toast' });
        }
        hideLoaderHandler();
      // window.location.reload();
        getCmsHomepageImage();
        setSelectedImageData()
      } else {
        if (!toast.isActive('error-toast')) {
          toast.error(response?.message, { toastId: 'error-toast' });
        }
        hideLoaderHandler();
      }
    }
    else{
      if (!toast.isActive('warning-toast')) {
        toast.warn('Please select an image', { toastId: 'warning-toast' });
      }
    }
  };

  return (
   <>
     <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "1.334",
                letterSpacing: "0em",
                margin: "16px 0",
              }}
            >
              Image Section
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8} className="homepage-images">
         
            <label htmlFor="upload-img">
            
              <img 
              src={selectedImageData ? URL.createObjectURL(selectedImageData) : `${imageData}?v=${new Date().getTime()}`} alt="img"/>
       
              <input
                type="file"
                id="upload-img"
                hidden
                onChange={onImageChange}
                ref={imageInputRef}
              />
            </label>
          </Grid>

          <Grid item xs={12} md={12} lg={12} className="save-btn">
            <Button 
              type="submit"
              variant="blue"
              onClick={() => {
                updateHomepageImage();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <ToastContainer containerId={'home-img'}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
   </>
  )
}

export default HomepageImage