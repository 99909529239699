import MainLayout from '../../Layouts/MainLayout';
import Reports from './Reports';
const ReportsContainer = () => {
  return (
    <MainLayout
      panelTitle={'Reports'}
      panelSubTitle={'Manage reports from here.'}
    >
      <Reports />
    </MainLayout>
  );
};

export default ReportsContainer;
