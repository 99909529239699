import MainLayout from '../../Layouts/MainLayout';
import { WELCOME_TO_ADMIN_DASHBOARD } from '../../lib/ValidationMessage';
import Dashboard from './Dashboard';

const DashboardContainer = () => {
  return (
    <MainLayout
      panelTitle={'Dashboard'}
      panelSubTitle={WELCOME_TO_ADMIN_DASHBOARD}
    >
      {/* <Dashboard /> */}
    </MainLayout>
  );
};

export default DashboardContainer;
