import { useContext, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginService from '../Services/loginService';
import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { closingTime, errorMessage } from '../lib/helper';
  
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('site') || '');
  const navigate = useNavigate();
  const loginAction = async (username, password) => {
    try {
      const response = await LoginService(username, password);
      const result = await response.json();
      if (result.data) {
        setUser(result.data.first_name);
        setToken(result.access_token);
        localStorage.setItem('site', result.access_token);
        navigate('/seminars');
        return;
      }
      throw result;
    } catch (error) {
      toast.error(errorMessage(error), {
        autoClose: closingTime,
      });
    }
  };
  const logOut = () => {
    setUser(null);
    setToken('');
    localStorage.removeItem('site');
  };
  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
