import {
    Box,
    Button,
    Typography,
  } from "@mui/material";
  import { useState } from "react";
  import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
  import {
    duplicateSeminarService,
  } from "../../Services/services";
  import {
    STATUS_200,
    FETCH_FAIL,
    SESSION_REPLICATED,
  } from "../../data/constants";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import "./seminarModule.css";
import { SEMINAR_DUPLICATED_SUCCESSFULLY, CANCEL, OK, WANT_TO_DUPLICATE } from "../../lib/ValidationMessage";
const DuplicateSeminar=({data,setDuplicateConfirmationDialog, fetchSeminarList})=>{
    const [showLoader, setShowLoader] = useState(false);
    const hideLoaderHandler = () => {
        setShowLoader(false);
      };
      const showLoaderHandler = () => {
        setShowLoader(true);
      };
    const closeDpltCnfDialog=()=>{
        setDuplicateConfirmationDialog({
            show:false,
            value:''
        })
    }
    const createDuplicateSeminar = async () => {    
       let payload = {
          seminar_id: data,
        };
        showLoaderHandler();
       let response = await duplicateSeminarService(payload);
        if (response.status === STATUS_200) {
            toast.success(SEMINAR_DUPLICATED_SUCCESSFULLY);
            hideLoaderHandler();
            fetchSeminarList(1); 
        } else if (response === FETCH_FAIL) {
            toast.error(response?.message);
          hideLoaderHandler();
        } else {
          toast.error(response?.message);
          hideLoaderHandler();
        }
      };
  return (
  <>
 <CustomDialog
            title="Duplicate Seminar"
            handleClose={closeDpltCnfDialog}
            actions={
              <Box>
                <Button className="cancel-btn" onClick={closeDpltCnfDialog}>{CANCEL}</Button>
                <Button className="confirm-btn"
                  onClick={() => {
                    setDuplicateConfirmationDialog((prevState) => {
                      return { ...prevState, show: false };
                    });
                    createDuplicateSeminar();
                  }}
                >
                  {OK}
                </Button>
              </Box>
            }
          >
            <Typography className="alert-text">
              {WANT_TO_DUPLICATE}
            </Typography>
          </CustomDialog>
  </>
  )
}

export default DuplicateSeminar;