import { Box } from '@mui/material';
import MainLayout from '../../../Layouts/MainLayout';
import UpdateProfile from './UpdateProfile';
import '../../ProfileSetting/profile.css';
const UpdatePasswordContainer = () => {
  return (
    <MainLayout
      panelTitle={'My Account'}
      panelSubTitle={'Manage account details from here.'}
    >
    <Box component={'div'} className='update-profile-container'>
    <UpdateProfile />
    </Box>
     
    </MainLayout>
  );
};

export default UpdatePasswordContainer;
