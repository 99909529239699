import MainLayout from '../../Layouts/MainLayout';
import { WELCOME_TO_ADMIN_DASHBOARD } from '../../lib/ValidationMessage';
import Seminars from './Seminars';
const SeminarContainer = () => {
  return (
    <MainLayout
      panelTitle={'Seminar'}
      panelSubTitle={WELCOME_TO_ADMIN_DASHBOARD}
    >
      <Seminars />
    </MainLayout>
  );
};

export default SeminarContainer;
