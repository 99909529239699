import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../Services/services';
import { STATUS_200, STATUS_401 } from '../data/constants';
import { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react';
import { useInfo } from '../context/UserContext';
export default function AccountSettingsMenus() {
    const navigate=useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const info=useInfo()
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
 
  useEffect(() => {
    info.getUserInfo();
  }, []);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const auth = useAuth();
  const handleLogout = () => {
    auth.logOut();
  };
 const first_name= info.user?.first_name ? info.user?.first_name : ''
 const last_name=info.user?.last_name ? info.user?.last_name :'';
 const fullName= first_name + ' ' + last_name
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="">
        <IconButton
             onClick={handleClick}
            size="small"
            sx={{ ml: 2,fontSize:15,hover:'none',}}
            style={{ backgroundColor: '#fff', borderRadius:'10px', padding:'12px', margin:"0 0 0 20px" }} 
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {fullName}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={()=>{handleClose(); navigate('/myAccount')}}>
        <ListItemIcon><AccountCircleIcon fontSize="small" style={{fontSize:'15px',marginRight:'4px',marginLeft:'3px'}}/></ListItemIcon>
        <span style={{fontSize:'12px',marginRight:'3px'}}> Profile </span>
        </MenuItem>
        <Divider style={{ marginBottom: '1px',marginTop:'1px' }}/>
        <MenuItem onClick={()=>{handleClose(); navigate('/manage-password')}}>
        <ListItemIcon ><UpdateSharpIcon fontSize="small" style={{fontSize:'15px',marginRight:'4px',marginLeft:'3px'}}/></ListItemIcon>
        <span style={{fontSize:'12px',marginRight:'3px'}}>Change Password </span>
        </MenuItem>
        <Divider style={{ marginBottom: '1px',marginTop:'1px' }}/>
        <MenuItem onClick={()=>{handleClose(); handleLogout()}}>
          <ListItemIcon>
            <Logout fontSize="small" style={{fontSize:'15px',marginRight:'4px',marginLeft:'3px'}}/>
          </ListItemIcon>
         <span style={{fontSize:'12px',marginRight:'3px'}}>Logout</span> 
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
