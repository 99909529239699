import {
  Box,
  Divider
} from "@mui/material";
import React, {useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RecommendedVideos from "./RecommendedVideos";
import BannerImages from "./BannerImages";
import Content from "./Content";
import HomepageImage from "./HomepageImage";
const Homepage = ({updateTab}) => {
  const [showLoader, setShowLoader] = useState(false);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
    const setActiveTabTwo=(data)=>{
   
    updateTab(data)
  }
  return (
    <>
      <Box className="image-container">
       <HomepageImage/>
        <Divider className="divider" />
       <Content/>
        <Divider className="divider" />
        {/* <Box>
          <RecommendedVideos page="homepage" setActiveTabOne={setActiveTabTwo} />
        </Box> */}

       
        <Box component={"div"}>
          <BannerImages />
        </Box>

      
      </Box>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
};

export default Homepage;
