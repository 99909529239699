import * as React from "react";
import { Box, Grid } from "@mui/material";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import SeminarsList from "./SeminarList";
import RegEnquiry from "./RegEnquiry";
import Attendees from "../SeminarAttendees/Attendees";
import "./seminarModule.css";
export default function Seminars() {
  //For tabs
  const [activeTab, setActiveTab] = React.useState("1");
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <Box>
      <TabContext value={activeTab}>
        <Grid container className="tab-container">
          <Grid item sm={9}>
            <TabList
              onChange={handleTabChange}
              aria-label="Seminar tab options."
            >
              <Tab label="Seminar List" value={"1"} />
              <Tab label="Reg. Enquiry" value={"2"} />
              {/* <Tab label="Attendees" value={"3"} /> */}
            </TabList>
          </Grid>
        </Grid>
        <TabPanel value="1">
          <SeminarsList />
        </TabPanel>
        <TabPanel value="2">
          <RegEnquiry />
        </TabPanel>
        {/* <TabPanel value="3">
          <Attendees />
        </TabPanel> */}
      </TabContext>
    </Box>
  );
}
