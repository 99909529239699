import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import CustomTabs from "../../Components/CustomTab/CustomTabs";
import Homepage from "./Homepage";
import RecommendedVideos from "./RecommendedVideos";
import ThePerson from "./ThePerson";
import VideoListing from "./VideoListing";

const CmsSection = () =>{
  let tabItems = [
    {
      label: "Homepage",
      id: 1,
    },

    {
      label: "Videos",
      id: 2,
    },
    {
      label: "The Person",
      id: 3,
    },
    
  ];

  const [activeTab, setTabActive] = useState("");
  const [counter, setCounter] = useState(1);
  const onTabClick = (data) => {
    setTabActive(data.id);
  };
const setUpdateTab=(data)=>{
 
  setCounter(a=>a+1);
setTabActive(data)
}

  return (
    <>
      <Box>
        <Grid container className="tab-container">
          <Grid item sm={12}>
            <CustomTabs count={counter}
            activeTab={activeTab}
              tabList={tabItems}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile={false}
              tabClick={onTabClick}
              tabsProp={{ indicatorColor: "#fff" }}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          {activeTab === 1 && <Homepage updateTab={setUpdateTab}/>}
          {activeTab === 2 && <VideoListing/>}
          {activeTab === 3 && <ThePerson/>}
          
        </Grid>
      </Box>
    </>
  );}

export default CmsSection;