import {Box,Button,FormControl,Grid,Input,InputAdornment,Typography} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import CustomTable from "../../Components/CustomTable/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import {seminarUserListService} from "../../Services/services";
import {STATUS_200,DATA_LIMIT} from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";
import { GO_BACK, NO_DATA, NO_PARTICIPANTS, SUBMIT, WELCOME_TO_ADMIN_DASHBOARD } from "../../lib/ValidationMessage";
const AttendedUsers = () => {
  let navigate = useNavigate();
  // let { seminarId } = useParams(); 
   const location = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [attendeesData, setAttendeesData] = useState([]);
  const [attendeesDataCount, setAttendeesDataCount] = useState(0);
  const [attendeesDataList, setAttendeesDataList] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState();
  const [seminarData, setSeminarData] = useState([]);
  const attendeesTableColumn = useMemo(
    () => [
     
      {
        accessorKey: "name",
        header: "NAME",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantName.charAt(0).toUpperCase() +
                row?.original?.participantName.slice(1)}
            </Typography>
          );
        },
      },
      {
        accessorKey: "email",
        header: "EMAIL",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantEmail}
            </Typography>
          );
        },
      },
      {
        accessorKey: "mobileNumber",
        header: "MOBILE NO.",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantMobile ? row?.original?.participantMobile : 'N/A'}
            </Typography>
          );
        },
      },
      {
        accessorKey: "gender",
        header: "GENDER",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantGender.charAt(0).toUpperCase() +
                row?.original?.participantGender.slice(1)}
            </Typography>
          );
        },
      },
    

      {
        accessorKey: "state",
        header: "STATE",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantState}
            </Typography>
          );
        },
      },
    ],
    []
  );
  const [params, setParams] = useState({
    semId: "",
    gender: "",
  });
  
  function getIdAndGender() {
    const queryString = location.pathname.split("/attended-user/")[1];
    const params = new URLSearchParams(queryString);
    const id = params.get("id");
    const gender = params.get("gender");
    getAttendeesData(page,id,gender);
    setParams({
      semId: id,
      gender: gender,
    });
    return { id, gender };
  }
  useEffect(() => {
   getIdAndGender();
  }, [page]);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };

  const getAttendeesData = async (page,id,gender) => {
    let payload = {
      seminar_id: id,
      page: page,
      limit: DATA_LIMIT,
      status:"1"
    };
    if (gender) {
      payload.gender = gender;
    }
    
    if(searchTerm){
      payload.search=searchTerm;
    }
    showLoaderHandler();
    const response = await seminarUserListService(payload);
    if (response.status === STATUS_200) {
     
        let participantData = response.data.rows.map((item) => {
          return {
            // participantSeminarId: item.seminar_id,
            participantName: item.session_registered_user.name,
            participantGender: item.session_registered_user.gender,
            participantEmail: item.session_registered_user.email,
            participantState: item.session_registered_user.state,
            participantMobile:item.session_registered_user.mobile,
            // participantRegDate: ,
          };
        });
        setAttendeesData([...participantData]);
        setAttendeesDataList([...participantData]);
        setAttendeesDataCount(response.data.count);
        hideLoaderHandler();
      
      
    }
    else if (response.status === 401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    }
      else {
        toast.error(response?.message);
        hideLoaderHandler();
      }
  };
  // Function to Export data
 
  
  const filterData = () => {
    if (searchTerm) {
      const filtered = attendeesDataList.filter(
        (item) =>
          item.participantName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.participantState
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.participantEmail.toLowerCase().includes(searchTerm.toLowerCase())
          ||
          item.participantMobile ==
            (searchTerm.toLowerCase()) 
      );
      setAttendeesData(filtered);
     getAttendeesData(1);
      setAttendeesDataCount(filtered.length);
    } else {
      getAttendeesData(page);
    }
  };
  const clearFilterHandle = async () => {
    setSearchTerm(""); // Reset search term to empty string immediately
    let payload = {
      seminar_id: params.semId,
      page: page,
      limit: DATA_LIMIT,
      status:"1"
    };
    if (params.gender) {
      payload.gender = params.gender;
    }
    if(searchTerm){
      payload.search=searchTerm;
    }
    const response = await seminarUserListService(payload);
    if (response.status === STATUS_200) {
      
        let participantData = response.data.rows.map((item) => {
          return {
           
            participantName: item.session_registered_user.name,
            participantGender: item.session_registered_user.gender,
            participantEmail: item.session_registered_user.email,
            participantState: item.session_registered_user.state,
            participantMobile:item.session_registered_user.mobile,
            
          };
        });
        setAttendeesData([...participantData]);
        setAttendeesDataList([...participantData]);
        setAttendeesDataCount(response.data.count);
        hideLoaderHandler();
      } 
     
    
    else if (response.status === 401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    }
      else {
        toast.error(response?.message);
        hideLoaderHandler();
      }
  }
  return (
    <>
      <MainLayout
        panelTitle={"Attended Users"}
        panelSubTitle={WELCOME_TO_ADMIN_DASHBOARD}
      >
        <Grid container>
          <Grid item sm={12}>
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                marginTop={2}
                marginBottom={2}
              >
                <KeyboardBackspaceIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Typography marginLeft={2}>{GO_BACK}</Typography>
              </Box>
              <Grid
        container
        className="search-container"
      >
        <Grid item sm={8}>
          <FormControl variant="textFieldLight"  sx={{marginLeft:'0'}}>
            <Input
              className="inputType1"
              id="search-field"
              fullWidth
              type={"text"}
              placeholder="Search here"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  filterData(e.target.value);
                }
              }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <Button variant="blue"  onClick={filterData}
            sx={{marginLeft:'12px'}}>
          {SUBMIT}
            </Button>  
            <Button
                variant="blue"
                type="submit"
                sx={{
                  backgroundColor: "#464255",
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "#464255 !important",
                  },
                }}
                onClick={clearFilterHandle}
              >
                Clear Filter
              </Button> 
        </Grid>
       
      </Grid>

      <CustomTable
            columns={attendeesTableColumn}
            data={attendeesData}
            positionActionsColumn={false}
            enableSorting={false}
            enableRowActions={false}
            enablePagination={false}
            enableTopToolbar={false}

          />
          <Box className="pagination-section">
            <CustomPagination
              className="pagination-bar"
              currentPage={page}
              totalCount={attendeesDataCount}
              siblingCount={0}
              showPageLimit={false}
              pageLimitArray={false}
              pageSize={pageSize}
              onPageChange={setPage}
              handleChangePageLimit={false}
              loading={false}
            />
          </Box>
            </Box>
          </Grid>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Backdrop
            sx={{ color: "#fff", zIndex: 1400 }}
            open={showLoader}
            onClick={hideLoaderHandler}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </MainLayout>
    </>
  );
};
export default AttendedUsers;
