import React, { useEffect } from "react";
import { Box, Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  assignSeminarToUsersService,
  fetchSeminarsService,
} from "../../Services/services";
import { STATUS_200, FETCH_FAIL, STATUS_401 } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./seminarModule.css";
import { SEMINAR_ASSIGNED_SUCCESSFULLY, SEMINAR_REQUIRED, SUBMIT} from "../../lib/ValidationMessage";
import moment from "moment/moment";

function AssignSeminar({
  selectedId,
  assignPageShow,
  getRegisterEnquiryList,
  setAssignPageShow,
}) {
  const current_date = new Date();
  const [showLoader, setShowLoader] = useState(false);
  const [seminarList, setSeminarList] = useState([]);
const [errMessage, setErrMessage]=useState();
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const hideAssignSeminarModal = () => {
    setAssignPageShow(false);
  };
  useEffect(() => {
    fetchSeminarsList();
  }, []);
  const fetchSeminarsList = async () => {
    let payload = {
      deleted:'0'
    };
    showLoaderHandler();
    const response = await fetchSeminarsService(payload);
   
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
        let seminarList = response.data.rows.map((item) => {
          return {
            seminarId: item.seminar_id,
            seminarName: item.name,
            seminarDate: item.date,
          };
        });
        const upComigSeminarList = seminarList.filter(
          (data) => data.seminarDate > moment(current_date).format("YYYY-MM-DD")
        );

        setSeminarList([...upComigSeminarList]);
        hideLoaderHandler();
      }
    } 
   
    else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };

  const assignSeminarToUsers = async () => {
    let payload = {
      seminar_id: formik.values.seminar_id,
      user_ids: selectedId.join(),
    };
    showLoaderHandler();
    let response = await assignSeminarToUsersService(payload);
    if (response.status === STATUS_200) {
      toast.success(SEMINAR_ASSIGNED_SUCCESSFULLY);
      hideLoaderHandler();
      formik.resetForm();
      hideAssignSeminarModal();
      getRegisterEnquiryList(1);
    } 
    else if(response?.flag == 'seats'){
     setErrMessage(response?.message)
    }
    else if (response === FETCH_FAIL) {
      toast.error(response?.message);
      hideAssignSeminarModal();
      hideLoaderHandler();
    } else {
      toast.error(response?.message);
      hideAssignSeminarModal();
      hideLoaderHandler();
    }
  };
  const formik = useFormik({
    initialValues: {
      user_ids: selectedId.join(),
      seminar_id: "",
    },
    validationSchema: Yup.object({
      seminar_id: Yup.string().required(SEMINAR_REQUIRED),
    }),
    onSubmit: assignSeminarToUsers,
  });

  return (
    <>
      <CustomDialog
        handleOpen={assignPageShow}
        title="Assign Seminar"
        fullWidth={true}
        maxWidth={"sm"}
        showClose={true}
        handleClose={hideAssignSeminarModal}
      >
        <Box
          component={"form"}
          onSubmit={formik.handleSubmit}
          noValidate
          marginTop={1}
        >
          <Grid container spacing={3} justifyContent={"center"}>
           
            <Grid item lg={12}>
              <TextField className="seminar-list"
                fullWidth
                id="seminar_id"
                name="seminar_id"
                select
                label="Seminar"
                placeholder="Select Seminar"
                value={formik.values.seminar_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.seminar_id && Boolean(formik.errors.seminar_id)
                }
                helperText={
                  formik.touched.seminar_id && formik.errors.seminar_id
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                required
              >
                {seminarList.map((option) => (
                  <MenuItem key={option.seminarId} value={option.seminarId}>
                    {option.seminarName}
                  </MenuItem>
                ))}
              </TextField>
             <Typography color={'red'} sx={{fontSize:'12px', margin:' 8px'}}>{errMessage}</Typography> 
            </Grid>
            <Grid item lg={12} sx={{
                  textAlign: "center"}}>
              <Button
                variant="blue"
                type="submit"
                size="large"
                sx={{
                  borderRadius: 3,
                }}
              >
              {SUBMIT}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </>
  );
}
export default AssignSeminar;
