// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .header-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 2.5rem;
}
.profile-menu{
    display: flex;
}
.notification-container{
    text-align: end;
}
.MuiBadge-badge{
    background-color: #58CDFF !important;
    border-radius: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/headerModule.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;AAC1B;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,oCAAoC;IACpC,6BAA6B;AACjC","sourcesContent":[".header .header-wrapper{\n    display: flex;\n    justify-content: space-between;\n    padding: 0.5rem 2.5rem;\n}\n.profile-menu{\n    display: flex;\n}\n.notification-container{\n    text-align: end;\n}\n.MuiBadge-badge{\n    background-color: #58CDFF !important;\n    border-radius: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
