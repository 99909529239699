export const SEMINARNAME_3_CHARACTERS_REQUIRED='Minimum 3 characters required.';
export const SEMINARNAME_REQUIRED='Please enter seminar name.';
export const AGE_GROUP_REQUIRED='Please select age group.';
export const CITY_REQUIRED='Please select a city.';
export const SEMINAR_REQUIRED='Please select a seminar.';
export const LEVEL_REQUIRED='Please select a level.';
export const SEMINAR_DATE_REQUIRED='Please enter seminar date';
export const SEMINAR_DATE_CANNOT_BE_LESS_THAN_CURRENT_DATE='Date cannot be less than current date.';
export const SEMINAR_TIME_REQUIRED='Please set time.';
export const MIN_1_SEAT_REQUIRED='Minimum 1 seat is required';
export const SEMINAR_SEATS_REQUIRED='Please enter seat number';
export const SEMINAR_VENUE_REQUIRED='Please enter seminar venue.';
export const NUMBER_REQUIRED='Only numbers are allowed!';
export const DURATION_REQUIRED='Please enter duration.';
export const MORE_THAN_0_VALIDATION='Cannot be less than zero';
export const MORE_THAN_1_VALIDATION='Cannot be less than one';
export const LESS_THAN_24HRS_VALIDATION='Cannot be greater than 24 hours!';
export const SEMINAR_SCHEDULE_REQUIRED='Please enter schedule';
export const DESCRIPTION_REQUIRED='Please enter description.';
export const VALID_EMAIL='Please enter valid email address.';
export const EMAIL_REQUIRED='Please enter your email address.';
export const PASSWORD_REQUIRED='Please enter your password.';
export const CANNOT_BE_BLANK ='Cannot be blank!';
export const CANNOT_BE_LESS_THAN_0 ='Seats cannot be less than zero!';
export const MINIMUM_SEAT_REQUIRED = 'Minimum 1 seat required!';
export const CANNOT_BE_MORE_THAN_TOTAL_SEATS ='Cannot be more than total number of seats!';
export const REMAINING_SEATS_VALIDATION = 'Remaining seats cannot be greater than total seats!';
export const SESSION_EXPIRED = 'Session expired! Please login again.';
export const  WANT_TO_EDIT='Are you sure you want to edit this seminar?';
export const  WANT_TO_REMOVE='Are you sure you want to delete this seminar?';
export const WANT_TO_PROCEED='Are you sure you want to perform this action?';
export const WANT_TO_DUPLICATE='Are you sure you want to duplicate this seminar?';
export const SEATS_UPDATED_SUCCESSFULLY='Seats updated successfully!';
export const SEATS_CREATED_SUCCESSFULLY='Seminar created successfully!';
export const SEMINAR_UPDATED_SUCCESSFULLY="Seminar updated successfully!";
export const SEMINAR_DELETED_SUCCESSFULLY='Seminar deleted successfully!';
export const ENQUIRY_REJECTED_SUCCESSFULLY='Enquiry rejected successfully!';
export const SEMINAR_DUPLICATED_SUCCESSFULLY='Seminar duplicated successfully!';
export const SEMINAR_ASSIGNED_SUCCESSFULLY='Seminar assigned successfully!';
export const VIDEO_UNPUBLISHED_SUCCESSFULLY='Video unpublished successfully';
export const VIDEO_PUBLISHED_SUCCESSFULLY='Video published successfully';
export const NETWORK_ERROR='There was some network issue.';
export const TRY_LATER='Please try again later.';
export const CANCEL='Cancel';
export const OK='Ok';
export const SUBMIT='Submit';
export const CONFIRM='Confirm';
export const GO_BACK='Go Back';
export const EXPORT='Export';
export const CREATE_NEW='Create New';
export const NO_PARTICIPANTS='No participants in the seminar right now.';
export const NO_DATA="No data found";
export const WELCOME_TO_ADMIN_DASHBOARD="Welcome to Sandeep Maheshwari Admin Dashboard";
export const ENTER_EMAIL="Email is required!";
export const FIRST_NAME_REQUIRED="First name is required.";
export const LAST_NAME_REQUIRED="Last name is required.";
export const MOBILE_NO_REQUIRED="Mobile number is required";
export const INVALID_MOBILE_NO="Mobile number is invalid";
export const ADMIN_ID_REQUIRED="Admin id is required.";
export const THIS_FIELD_IS_REQUIRED="This field is required";
export const DETAILS_UPDATED_SUCCESSFULLY="Details updated successfully!";
export const PASSWORD_UPDATED_SUCCESSFULLY="Password updated successfully!";
export const CONTENT_UPDATED_SUCCESSFULLY="Content updated successfully!";
export const IMAGE_UPDATED_SUCCESSFULLY="Image uploaded successfully!";
export const IMAGE_DELETED_SUCCESSFULLY="Image deleted successfully!";
export const VIDEO_UPDATED_SUCCESSFULLY="Video uploaded successfully!";
export const VIDEO_DELETED_SUCCESSFULLY="Video deleted successfully!";
export const ENTER_PASSWORD="Please enter your password";
export const  PASSWORD_MUST_MATCH="Passwords must match";
export const URL_IS_REQUIRED="URL is required";
export const PWD_MAX_VALIDATION="Password should be less than 16 Character";
export const PWD_MIN_VALIDATION="Password should be more than 8 Character";
export const PWD_CONTAIN_SPECIAL_CHARACTER_VALIDATION="Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character";