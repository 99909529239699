export const closingTime = 2500;
export  const ageGroups = [
  {
    value: "16-18",
    label: "16-18",
  },
  {
    value: "19-21",
    label: "19-21",
  },
  {
    value: "22-24",
    label: "22-24",
  },
  {
    value: "25-26",
    label: "25-26",
  },
  {
    value: "27-30",
    label: "27-30",
  },
];
export  const searchByFilter = [
  {
    value: "gender",
    label: "Gender",
  },
  {
    value: "state",
    label: "State",
  },
  {
    value: "age_group",
    label: "Age group",
  },
 
];
export  const attendeesSearchByFilter = [
  {
    value: "gender",
    label: "Gender",
  },
  {
    value: "state",
    label: "State",
  }
 
];
export const cities = [
  {
    value: "Noida",
    label: "Noida",
  },
  {
    value: "Gurugram",
    label: "Gurugram",
  },
  {
    value: "Delhi",
    label: "Delhi",
  },
  {
    value: "Pune",
    label: "Pune",
  },
];
export const seminarLevels = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
];
export const errorMessage = (response) => {
  try {
    return (
      response?.response?.data?.message ||
      response?.data?.response?.message ||
      response?.data?.message ||
      response?.message ||
      "Something went wrong"
    );
  } catch (e) {
    return e?.message || "Something went wrong";
  }
};
export function downloadBlobXLSFile(data, name = 'File') {
  // Its important to set the 'Content-Type': 'blob' and responseType:'blob'.

  try {
    const outputFilename = `${name}.xlsx`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    throw Error(error);
  }
}
export const gender = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];


export const attendee_status = [
  {
    value: "0",
    label: "Pending",
  },
  {
    value: "1",
    label: "Confirmed",
  },
  {
    value: "2",
    label: "Rejected",
  },
];

