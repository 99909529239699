import {
  Box,Button,Grid,Link,Typography,InputAdornment,FormControl,Input} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useMemo, useState } from "react";
import CustomTable from "../../Components/CustomTable/CustomTable";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import {fetchSeminarsService, manageSeminarSeatsService,
} from "../../Services/services";
import { STATUS_200, FETCH_FAIL, SEATS_UPDATED, DATA_LIMIT, STATUS_401 } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./seminarModule.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as EditIcon } from "../../Assets/Svg/edit.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Svg/delete.svg";
import IconButton from "@mui/material/IconButton";
import ManageSeats from "./ManageSeats";
import EditSeminar from "./EditSeminar";
import CreateSeminar from "./CreateSeminar";
import DeleteSeminar from "./DeleteSeminar";
import DuplicateSeminar from "./DuplicateSeminar";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import { CANCEL, WANT_TO_EDIT, OK, SEATS_UPDATED_SUCCESSFULLY, CREATE_NEW, SUBMIT } from "../../lib/ValidationMessage";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const SeminarsList = () => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(DATA_LIMIT);
  const [page, setPage] = useState(1);
  useEffect(() => {
    fetchSeminarsList(page);
  }, [page]);
 
  const [showEditConfirmationDialog, setShowEditConfirmationDialog] = useState({
    show: false,
    value: "",
  });
  const [showDuplicateConfirmationDialog, setDuplicateConfirmationDialog] =
    useState({
      show: false,
      value: "",
    });
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState({
      show: false,
      value: "",
    });
  const [editSeminarDialog, setEditSeminarDialog] = useState(false);
  const [createSeminarDialog, setCreateSeminarDialog] = useState(false);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const closeEdtCnfDialog = () => {
    setShowEditConfirmationDialog({
      show: false,
      value: "",
    });
  };

  const seminarTableColumns = useMemo(
    () => [
      {
        acceaccessorKey: "seminarId",
        header: "SEM.ID",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.seminarId}
            </Typography>
          );
        },
      },
      {
        acceaccessorKey: "seminarName",
        header: "SEM.NAME",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Link
              onClick={() => {navigate(`/seminar-participants/${row?.original?.seminarId}`)
               
              }}
            >
              {row?.original?.seminarName}
            </Link>
          );
        },
      },
      {
        acceaccessorKey: "seminarDate",
        header: "SEM.DATE",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
            {moment(row?.original?.seminarDate, 'DD/MM/YY', true).isValid() ? moment(row?.original?.seminarDate, 'DD/MM/YY').format('DD-MM-YYYY'): moment(row?.original?.seminarDate).format("DD-MM-YYYY")}
              {/* {moment(row?.original?.seminarDate).format("DD-MM-YYYY")} */}
            </Typography>
          );
        },
      },
      {
        acceaccessorKey: "seminarTime",
        header: "SEM.TIME",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.seminarTime}
            </Typography>
          );
        },
      },
     
    
      {
        acceaccessorKey: "seminarCity",
        header: "CITY",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.seminarCity}
            </Typography>
          );
        },
      },
      {
        acceaccessorKey: "numOfSeats",
        header: "NO. OF SEATS",
        size: 30,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.numOfSeats}
            </Typography>
          );
        },
      },
    ],
    []
  );
  const [seminarData, setSeminarData] = useState([]);
  const [seminarDataList, setSeminarDataList] = useState([]);
  const [seminarDataCount, setSeminarDataCount] = useState(0);
  const fetchSeminarsList = async (page) => {
    let payload = {
      page: page,
      limit: pageSize,
      deleted:'0'
    };
    if(searchTerm){
      payload.search=searchTerm
    }
    showLoaderHandler();

    const response = await fetchSeminarsService(payload);
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
        let seminarList = response.data.rows.map((item) => {
          return {
            seminarId: item.seminar_id,
            seminarName: item.name,
            seminarDate: item.date,
            seminarTime: item.time,
            seminarLevel: item.level,
            seminarCity: item.city,
            numOfSeats: item.seat,
            seminarVenue: item.venue,
            seminarDuration: item.duration,
            seminarSchedule: item.schedule,
            seminarDescription: item.description,
            maleSeats:item.male_seats,
            femaleSeats:item.female_seats,
          };
        });
        setSeminarData([...seminarList]);
        setSeminarDataList([...seminarList]);
        setSeminarDataCount(response.data.count);
        hideLoaderHandler();
      }
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message,{toastId:'token-expire-error'});
    } else {
      toast.error(response?.message,{toastId:'error'});
      hideLoaderHandler();
    }
  };
  const [showCsDialog, setShowCSDialog] = useState(false);
  const showCSModal = () => {
    setCreateSeminarDialog(true);
    setShowCSDialog(true);
  };

  const cnfEditSeminar = (seminarDetails) => {
    

    setShowEditConfirmationDialog({
      show: true,
      value: seminarDetails,
    });
  };
  const cnfDeleteSeminar = (seminarId) => {
    setShowDeleteConfirmationDialog({
      show: true,
      value: seminarId,
    });
  };
  const cnfDuplicateSeminar = (seminarId) => {
    setDuplicateConfirmationDialog({
      show: true,
      value: seminarId,
    });
  };

  const [showManageSeatsModal, setShowManageSeatsModal] = useState({
    show: false,
    data: "",
  });

  //To activate manage seat modal and assign data of selected seminar.
  const manageSeats = (seminarDetails) => {
    setShowManageSeatsModal({
      show: true,
      data: seminarDetails,
    });

  };
  //To close manage seat modal.
  const closeManageSeatModal = () => {
    setShowManageSeatsModal({
      show: false,
      data: "",
    });
  };

  //To update seat data
  const updateSeats = async (values) => {
    let payload, response;
    payload = {
      seminar_id: values.seminarId,
      seminar_total_seats: values.seminarTotalSeats,
      male_seats: values.maleSeats,
      female_seats: values.femaleSeats,
      remaining_seats: values.remainingSeats,
    };
    showLoaderHandler();
    response = await manageSeminarSeatsService(payload);
    if (response.status === STATUS_200) {
    
        toast.success(SEATS_UPDATED_SUCCESSFULLY);
        hideLoaderHandler();
        setShowManageSeatsModal({ show: false, data: "" });
        fetchSeminarsList();
     
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };
  useEffect(() => {
    fetchSeminarsList(page);
  }, []);

  // Function to filter data based on search term
  const filterData = () => {
    if (searchTerm) {
      const filtered = seminarDataList.filter(
        (item) =>
          item.seminarName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.seminarCity.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSeminarData(filtered);
      fetchSeminarsList(1);
      setSeminarDataCount(filtered.length);
    } else {
      fetchSeminarsList(page);
    }
  };
  const clearFilterHandle = async () => {
    setSearchTerm(""); // Reset search term to empty string immediately
    const payload={
      page:page,
      limit:pageSize,
      deleted:'0'
    }
    const response = await fetchSeminarsService(payload);
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
        let seminarList = response.data.rows.map((item) => {
          return {
            seminarId: item.seminar_id,
            seminarName: item.name,
            seminarDate: item.date,
            seminarTime: item.time,
            seminarLevel: item.level,
           seminarCity: item.city,
            numOfSeats: item.seat,
            seminarVenue: item.venue,
            seminarDuration: item.duration,
            seminarSchedule: item.schedule,
            seminarDescription: item.description,
            maleSeats:item.male_seats,
            femaleSeats:item.female_seats,
          };
        });
        setSeminarData([...seminarList]);
        setSeminarDataList([...seminarList]);
        setSeminarDataCount(response.data.count);
        hideLoaderHandler();
      }
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  }
  return (
    <>
      <Grid container>
        <Grid item sm={12}>
         
            <Grid
        container
        className="search-container"
        style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
      >
        <Grid item sm={8} display={'flex'} alignItems={'center'}>
          <FormControl variant="textFieldLight" sx={{marginLeft:'0'}} >
            <Input
              className="inputType1"
              id="search-field"
              fullWidth
              type={"text"}
              placeholder="Search here"
              onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    filterData(e.target.value)
                  }
                }}
              endAdornment={
                <InputAdornment position="end" >
                  <SearchIcon />
                </InputAdornment>
              }
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </FormControl>
          <Button variant="blue" onClick={filterData} sx={{marginLeft:'12px'}}>
         {SUBMIT}
            </Button>   
            <Button
                variant="blue"
                type="submit"
                sx={{
                  backgroundColor: "#464255",
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "#464255 !important",
                  },
                }}
                onClick={clearFilterHandle}
              >
                Clear Filter
              </Button> 
        </Grid>
        <Grid item sm={3} textAlign={"right"}>
          <Box>
            <Button variant="blue" onClick={showCSModal}>
              {CREATE_NEW}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box textAlign={"right"}>
              <CustomTable
                columns={seminarTableColumns}
                data={seminarData}
                positionActionsColumn="last"
                enableSorting={true}
                enableTopToolbar={false}
                enableRowActions={true}
                enablePagination={false}
                renderRowActions={({ row }) => (
                  <Box>
                    <Button
                      variant="contained"
                      className="btnLight fontWeight500 padding5x10"
                      sx={{ marginLeft: "7px", marginRight: "7px" }}
                      onClick={() => {
                        cnfDuplicateSeminar(row.original.seminarId);
                      }}
                    >
                      DUPLICATE
                    </Button>
                    <Button
                      variant="contained"
                      className="btnLight fontWeight500 padding5x10"
                      sx={{ marginLeft: "7px", marginRight: "7px" }}
                      onClick={() => {
                        manageSeats(row.original);
                      }}
                    >
                      MANAGE SEATS
                    </Button>
                    <IconButton
                      aria-label="editSeminar"
                      onClick={() => {
                        cnfEditSeminar(row.original);
                      }}
                      color="primary"
                      sx={{ marginLeft: "7px", marginRight: "7px" }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="deleteSeminar"
                      onClick={() => {
                        cnfDeleteSeminar(row.original.seminarId);
                      }}
                      color="primary"
                      sx={{ marginLeft: "7px", marginRight: "7px" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              />
              <Box className="pagination-section">
                <CustomPagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={seminarDataCount}
                  siblingCount={0}
                  showPageLimit={false}
                  pageLimitArray={false}
                  pageSize={pageSize}
                  onPageChange={setPage}
                  handleChangePageLimit={false}
                  loading={false}
                />
              </Box>
            </Box>
        </Grid>
        {showEditConfirmationDialog.show && (
          <CustomDialog
            title="Edit Seminar"
            handleClose={closeEdtCnfDialog}
            actions={
              <Box>
                <Button className="cancel-btn" onClick={closeEdtCnfDialog}>{CANCEL}</Button>
                <Button className="confirm-btn"
                  onClick={() => {
                    setShowEditConfirmationDialog((prevState) => {
                      return { ...prevState, show: false };
                    });
                    setEditSeminarDialog(true);
                  }}
                >
                  {OK}
                </Button>
              </Box>
            }
          >
            <Typography className="alert-text">{WANT_TO_EDIT}</Typography>
          </CustomDialog>
        )}
        {showDuplicateConfirmationDialog.show && (
          <DuplicateSeminar
            data={showDuplicateConfirmationDialog.value}
            setDuplicateConfirmationDialog={setDuplicateConfirmationDialog}
            fetchSeminarList={fetchSeminarsList}
          />
        )}
        {showDeleteConfirmationDialog.show && (
          <DeleteSeminar
            data={showDeleteConfirmationDialog.value}
            setShowDeleteConfirmationDialog={setShowDeleteConfirmationDialog}
            fetchSeminarList={fetchSeminarsList}
          />
        )}
        {showManageSeatsModal.show && (
          <ManageSeats
            closeDialog={closeManageSeatModal}
            close={true}
            open={true}
            updateSeats={updateSeats}
            seminarDetail={showManageSeatsModal.data}
          />
        )}
        {createSeminarDialog && (
          <CreateSeminar
            createSeminarDialog={createSeminarDialog}
            fetchSeminarList={fetchSeminarsList}
            setCreateSeminarDialog={setCreateSeminarDialog}
          />
        )}
        {editSeminarDialog && (
          <EditSeminar
            data={showEditConfirmationDialog?.value}
            editSeminarDialog={editSeminarDialog}
            fetchSeminarList={fetchSeminarsList}
            setEditSeminarDialog={setEditSeminarDialog}
          />
        )}
     
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
           
    </>
  );
};
export default SeminarsList;
