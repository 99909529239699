import React, { useState } from "react";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import { Box, TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CANNOT_BE_BLANK, CANNOT_BE_LESS_THAN_0, CANNOT_BE_MORE_THAN_TOTAL_SEATS, CONFIRM, MINIMUM_SEAT_REQUIRED, MORE_THAN_0_VALIDATION, NUMBER_REQUIRED, REMAINING_SEATS_VALIDATION, 
   } from "../../lib/ValidationMessage";
import moment from "moment";
const ManageSeats = ({
  closeDialog = () => {},
  close,
  updateSeats = () => {},
  open,
  seminarDetail,
}) => {
 
  const submitHandler = () => {
    updateSeats(manageSeatsFormik.values);
  };
  const manageSeatsFormik = useFormik({
    initialValues: {
      seminarId: seminarDetail.seminarId,
      seminarTotalSeats: seminarDetail.numOfSeats,
      seminarDate: seminarDetail.seminarDate,
      maleSeats: seminarDetail.maleSeats,
      femaleSeats: seminarDetail.femaleSeats,
      remainingSeats:
       Number(seminarDetail.numOfSeats) - (Number(seminarDetail.maleSeats) + Number(seminarDetail.femaleSeats)),
    },
    validationSchema: Yup.object({
      seminarTotalSeats: Yup.number(NUMBER_REQUIRED)
        .required(CANNOT_BE_BLANK)
        .positive(CANNOT_BE_LESS_THAN_0)
        .min(1, MINIMUM_SEAT_REQUIRED),
      femaleSeats: Yup.number(NUMBER_REQUIRED)
        .required(CANNOT_BE_BLANK)
        .positive(CANNOT_BE_LESS_THAN_0)
        .min(0, MORE_THAN_0_VALIDATION)
        .max(
          Yup.ref("seminarTotalSeats"),
          CANNOT_BE_MORE_THAN_TOTAL_SEATS
        ),
      maleSeats: Yup.number(NUMBER_REQUIRED)
        .required(CANNOT_BE_BLANK)
       
        .min(0, MORE_THAN_0_VALIDATION)
        .max(
          Yup.ref("seminarTotalSeats"),
          CANNOT_BE_MORE_THAN_TOTAL_SEATS
        ),
      remainingSeats: Yup.number(NUMBER_REQUIRED)
        .required(CANNOT_BE_BLANK)
        .positive(CANNOT_BE_LESS_THAN_0)
        .min(0, MORE_THAN_0_VALIDATION)
        .max(
          Yup.ref("seminarTotalSeats"),
          REMAINING_SEATS_VALIDATION
        )
       ,
    }),
    onSubmit: submitHandler,
  });
  //To update remaining seats
  const remainingSeats = (totalSeatValue, maleSeatValue, femaleSeatValue) => {
    let totalSeats =
      totalSeatValue || manageSeatsFormik.values.seminarTotalSeats;
    let maleSeats = maleSeatValue  || manageSeatsFormik.values.maleSeats;
    let femaleSeats = femaleSeatValue  || manageSeatsFormik.values.femaleSeats;
    let remainingSeat = +totalSeats - (+maleSeats + +femaleSeats);
    manageSeatsFormik.setFieldValue("remainingSeats", remainingSeat);
  };
  return (
    <CustomDialog
      title="Manage Seats"
      handleClose={closeDialog}
      showClose={close}
      handleOpen={open}
    >
      <Box
        key={"manageSeats-form-container"}
        textAlign={"center"}
        component={"form"}
        noValidate
        onSubmit={manageSeatsFormik.handleSubmit}
      >
        <TextField
          key={"seminarId"}
          id="seminarId"
          name="seminarId"
          disabled
          value={manageSeatsFormik.values.seminarId}
          onChange={manageSeatsFormik.handleChange}
          label={"Seminar ID"}
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <TextField
          disabled
          key={"seminarDate"}
          id="seminarDate"
          name="seminarDate"
          value={moment(manageSeatsFormik.values.seminarDate).format('DD-MM-YYYY')}
          required
          label={"Seminar Date"}
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <TextField
          key={"seminarTotalSeats"}
          id="seminarTotalSeats"
          name="seminarTotalSeats"
          required
          InputLabelProps={{
            shrink: true,
          }}
          value={
            manageSeatsFormik.values.seminarTotalSeats 
          }
          error={
            manageSeatsFormik.touched.seminarTotalSeats &&
            Boolean(manageSeatsFormik.errors.seminarTotalSeats)
          }
          helperText={
            manageSeatsFormik.touched.seminarTotalSeats &&
            manageSeatsFormik.errors.seminarTotalSeats
          }
          onChange={(e) => {
            manageSeatsFormik.setFieldValue(
              "seminarTotalSeats",
              +e.target.value
            );
            remainingSeats(e.target.value, undefined, undefined);
          }}
          label={"Total seats in seminar"}
          fullWidth
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <TextField
          key={"maleSeats"}
          id="maleSeats"
          name="maleSeats"
          type="number"
          required
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: 0 } }}
          value={
            manageSeatsFormik.values.maleSeats 
          }
          onChange={(e) => {
            manageSeatsFormik.setFieldValue("maleSeats", e.target.value);
            remainingSeats(undefined, e.target.value, undefined);
          }}
          error={
            manageSeatsFormik.touched.maleSeats &&
            Boolean(manageSeatsFormik.errors.maleSeats)
          }
          helperText={
            manageSeatsFormik.touched.maleSeats &&
            manageSeatsFormik.errors.maleSeats
          }
          label={"Seats for male"}
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <TextField
          key={"femaleSeats"}
          id="femaleSeats"
          name="femaleSeats"
          type="number"
          required
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: 0 } }}
          value={
            manageSeatsFormik.values.femaleSeats 
          }
          onChange={(e) => {
            manageSeatsFormik.setFieldValue("femaleSeats", e.target.value);
            remainingSeats(undefined, undefined, e.target.value);
          }}
          error={
            manageSeatsFormik.touched.femaleSeats &&
            Boolean(manageSeatsFormik.errors.femaleSeats)
          }
          helperText={
            manageSeatsFormik.touched.femaleSeats &&
            manageSeatsFormik.errors.femaleSeats
          }
          label={"Seats for female"}
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <TextField
          key={"remainingSeats"}
          id="remainingSeats"
          name="remainingSeats"
          type="number"
          required
          value={
            manageSeatsFormik.values.remainingSeats 
          }
          
          error={
            manageSeatsFormik.touched.remainingSeats &&
            Boolean(manageSeatsFormik.errors.remainingSeats)
          }
          helperText={
            manageSeatsFormik.touched.remainingSeats &&
            manageSeatsFormik.errors.remainingSeats
          }
          disabled
          label={"Remaining seats"}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <Button variant="blue" sx={{ marginTop: "10px" }} type="submit">
     {CONFIRM}
        </Button>
      </Box>
    </CustomDialog>
  );
};
export default ManageSeats;
