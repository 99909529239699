import { Box } from '@mui/material';
import DashboardLogo from '../DashboardLogo/dashboardLogo';
import { adminPanelImages } from '../../Assets/Images';
import SpeedIcon from '@mui/icons-material/Speed';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LockIcon from '@mui/icons-material/Lock';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../../context/AuthProvider';
import LogoutIcon from '@mui/icons-material/Logout';
import styles from './sidebar.module.css';
const Sidebar = () => {
  const auth = useAuth();
  const handleLogout = () => {
    auth.logOut();
  };
  const prom = ({ isActive }) => {
    if (isActive) {
      return styles.sidebarActieLink + ' ' + styles.sidebarNavlink;
    } else {
      return styles.sidebarNavlink;
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '15px',
        padding: '16px 24px 0px 24px',
        height: '98%',
        alignItems: 'center',
      }}
    >
      <DashboardLogo
        source={adminPanelImages.dashboardLogoOrange}
        size={'80%'}
      />
      <Box
        textAlign={'left'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        marginTop={4}
      >
        {/* <NavLink to="/dashboard" className={prom}>
          <SpeedIcon fontSize="large" sx={{ marginRight: '3px' }} />
          {'Dasboard'}
        </NavLink> */}
        <NavLink to="/cms" className={prom}>
          <DescriptionIcon fontSize="large" sx={{ marginRight: '3px' }} />
          {'Website CMS'}
        </NavLink>
        <NavLink to="/seminars" className={prom}>
          <PersonIcon fontSize="large" sx={{ marginRight: '3px' }} />
          {'Seminar'}
        </NavLink>
        <NavLink to="/reports" className={prom}>
          <AssessmentIcon fontSize="large" sx={{ marginRight: '3px' }} />
          {'Reports'}
        </NavLink>
      
      </Box>
    </Box>
  );
};

export default Sidebar;
