import React, { useEffect, useState } from 'react'
import { FormHelperText } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import './style.css'

function CustomPhoneInput({divStyle, ...props}) {
  const [toggleComp, setToggleComp] = useState(true)
  
  useEffect(()=>{
    setToggleComp(false)
    setTimeout(()=>{
      setToggleComp(true)
    },1)
  },[props?.country])
  
  return <div style={{position: "relative", ...divStyle}}>
    {props.label && <label className='floatingLablePhone' style={props.disabled?{color: 'rgba(0, 0, 0, 0.38)'} : {color: "rgb(52, 52, 52)"}}>
      {props.label}
    </label>}
    {toggleComp && <PhoneInput
      country={'in'}
      disableDropdown
      disableCountryCode
      prefix=''
      {...props} 
      inputStyle={{
        paddingTop: "14px",
        paddingBottom: "14px",
        height: 'auto',
        ...props.inputStyle
      }}
    />}
    {props.error?<FormHelperText error={true}>{props.helperText}</FormHelperText>: null}
  </div>
}

export default CustomPhoneInput;

CustomPhoneInput.defaultProps = {
  inputStyle: {},
  disabled: false,
  countryCodeEditable: true
}