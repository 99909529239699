import { createTheme } from '@mui/material';

export const customTheme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'Poppins'].join(','),
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          '&.inputType1': {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '1rem',
            color: '#A3A3A3',
          },
          '&.inputType1::before': {
            borderBottom: 'none !important',
          },
          '&.inputType1::after': {
            borderBottom: 'none !important',
          },
        },
      },
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: 'textFieldLight' },
          style: {
            borderRadius: '22px',
            padding: '12px',
            marginLeft: '12px',
            backgroundColor: '#E5F5FF',
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props:{variant: 'tableCell'},
          style:{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.875rem',
            color: '#464255',
            fontWeight:500,
            lineHeight: '24px',
          }
        },
        {
          props: { variant: 'titleText1' },
          style: {
            fontWeight: 600,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '1.5rem',
            color: '#464255',
          },
        },
        {
          props: { variant: 'titleText2' },
          style: {
            fontWeight: 400,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.875rem',
            color: '#464255',
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.marginLeft12': {
            marginLeft: '12px',
          },
          '&.noFocusChange': {
            '&.Mui-focused': {
              color: '#A3A3A3',
              top: '-5px',
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'labelType1' },
          style: {
            color: '#fff',
            fontSize: '18px',
          },
        },
        {
          props: { variant: 'labelType2' },
          style: {
            color: '#A3A3A3',
            fontSize: '0.875rem',
            fontFamily: 'Poppins, sans-serif',
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.textFieldType1': {
            margin: '15px 0px',
            '& .MuiInputBase-root': {
              border: '3px solid #fff',
              borderRadius: '10px',
              color: '#fff',
              padding: '0px',
              fontSize: '18px',
              lineHeight: '22px',
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              backgroundColor: 'transparent !important',
              color: '#fff !important',
              appearance: 'textfield !important',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#bcbebe',
          fontWeight: '500',
          fontSize: '0.875rem',
          fontFamily: "Poppins",
          '&.Mui-selected': {
            color: '#464255',
            backgroundColor: 'transparent',
            border:'none',
          },
          '&.Add-border': {
            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
            paddingRight: '34px',
            // marginRight: '16px',
            paddingLeft: '30px'
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'lightBlue' },
          style: {
            color: '#fff',
            backgroundColor: '#58CDFF',
            fontWeight: 600,
            fontSize: '18px',
            padding: '15px 0px',
            borderRadius: '10px',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#4FB8E5',
            },
          },
        },
        {
          props: { variant: 'white' },
          style: {
            backgroundColor: '#fff',
            padding: '16px',
            borderRadius: '22px',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#fff',
            },
          },
        },
        {
          props: { variant: 'grey' },
          style: {
            backgroundColor: '#F3F2F7',
            color:'#464255',
             fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '21px',
           margin:'0 5px',
            textTransform:'capitalize',
            padding: '10px 15px',
            borderRadius: '10px',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#F3F2F7',
              boxShadow:' 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
           
            },
          },
        },
        {
          props: { variant: 'blue' },
          style: {
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '21px',
            backgroundColor: '#58CDFF',
            textTransform:'capitalize',
            color: '#fff',
            padding: '10px 15px',
            borderRadius: '10px',
            margin:'0 5px',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#58CDFF',
              boxShadow:' 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
           
            },
          },
        },
        {
          props: { variant: 'red' },
          style: {
            backgroundColor: '#b14949',
            color:'#fff',
             fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '21px',
           margin:'0 5px',
            textTransform:'capitalize',
            padding: '10px 15px',
            borderRadius: '10px',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#b14949',
              boxShadow:' 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
           
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#464255',
          fontFamily: ['Poppins, sans-serif'].join(','),
          borderRadius: '8px',
          boxShadow: 'none',
          '&.btnLight': {
            backgroundColor: '#E5F5FF',
          },
          '&.fontWeight500': {
            fontWeight: 500,
          },
          '&.padding5x10': {
            padding: '5px 10px',
          },
          '&.padding16x24': {
            padding: '16px 24px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontsize: '19px',
          color: '#464255',
          fontFamily: ['Poppins', 'sans-serif'].join(','),
          '&.fontSize18': {
            fontSize: '18px',
          },
          '&.fontSize14': {
            fontSize: '14px',
          },
          '&.fontSize0875': {
            fontSize: '0.875rem',
          },
          '&  .fontWeight500': {
            fontWeight: 500,
          },
          '&.colorLightGray': {
            color: '#BCBEBE',
          },
          '&.radius18': {
            borderRadius: '18px',
          },
          '&.padding16x8': {
            padding: '16px 8px',
          },
          '&.width100': {
            width: '100%',
          },
        },
      },
    },
  },
});
