import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { useEffect } from "react";
import { DATA_LIMIT, STATUS_200, STATUS_401 } from "../../data/constants";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  fetchAllVideosService,
  getCmsDataService,
  publishVideosFromListService,
} from "../../Services/services";
import UploadNewVideo from "./uploadNewVideo";
import DeleteVideo from "./DeleteVideo";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import UploadVideoInVideoList from "./UploadVideoInVideoList";
import DeleteVideoFromVideoList from "./DeleteVideoFromVideoList";
import { VIDEO_PUBLISHED_SUCCESSFULLY, VIDEO_UNPUBLISHED_SUCCESSFULLY, VIDEO_UPDATED_SUCCESSFULLY } from "../../lib/ValidationMessage";
function VideoListing() {
  const [openVideoUploadDialog, setOpenVideoUploadDialog] = useState(false);
  const [openDeleteVideoDialog, setOpenDeleteVideoDialog] = useState(false);
  const [videoListData, setVideoListData] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState();
  const [pageSize, setPageSize] = useState(DATA_LIMIT);
  const [page, setPage] = useState(1);
  const [videoListDataCount, setVideoListDataCount] = useState(0);

  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    getAllVideos();
  }, [page]);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const getAllVideos = async () => {
    const payload = {
      page: page,
      limit: pageSize,
    };
    showLoaderHandler();
    const response = await fetchAllVideosService(payload);
    if (response.status === STATUS_200) {
      let videoData = response?.data?.rows;
      const videoDataList = [...videoData];
      setVideoListData(videoDataList);
      setVideoListDataCount(response?.data?.count);
      hideLoaderHandler();
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };
  const removeVideoDialog = (id) => {
    setOpenDeleteVideoDialog(true);
    setSelectedVideoId(id);
  };
  const togglePublishUnpublishHandle= async(id,status)=>{
const payload={
    video_id :id,
    publish:status
}
showLoaderHandler();
let response = await publishVideosFromListService(payload);
if (response.status === STATUS_200) {

  status == 1 ? toast.success(VIDEO_PUBLISHED_SUCCESSFULLY,{toastId:'publish-video'}) : toast.success(VIDEO_UNPUBLISHED_SUCCESSFULLY,{toastId:'unpublish-video'})
  hideLoaderHandler();
  getAllVideos();
} else {
  toast.error(response?.message);
  hideLoaderHandler();
 
}
  }
  return (
    <>
    
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        className="content-container"
      >
        <Grid item xs={3} md={3} lg={3}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "1.334",
              letterSpacing: "0em",
              margin: "16px 0",
            }}
          >
            Video(s) Section
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3} textAlign={"end"} className="save-btn">
          <Button variant="blue" onClick={() => setOpenVideoUploadDialog(true)}>
            Upload New Video
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        {videoListData.map((data, i) => {
          var regExp =
            /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
          var match = data?.video_url.match(regExp);
          const url = `https://www.youtube.com/embed/${match[2]}`;
          return (
            <React.Fragment key={i}>
              <Grid item xs={12} md={8} lg={8}>
                <Card sx={{ height: "250px", borderRadius: "16px" }}>
                  <CardActionArea sx={{ height: "100%", borderRadius: "16px" }}>
                    <CardMedia
                      component="iframe"
                      height="100%"
                      src={url}
                      title="YouTube video"
                    />
                  </CardActionArea>
                </Card>{" "}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="save-btn"
                margin={"24px 0"}
              >
              {data?.publish == 1 &&  <Button variant="blue" onClick={()=>{togglePublishUnpublishHandle(data?.video_id,"0")}}>Unpublish</Button> 
              }{data?.publish == 0 &&
               <Button variant="blue" onClick={()=>{togglePublishUnpublishHandle(data?.video_id,"1")}}>Publish</Button>
               }
                
               
                <Button
                  disabled={showLoader ? true : false}
                  variant="red"
                  onClick={() => {
                    removeVideoDialog(data?.video_id);
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Box className="pagination-section" sx={{ margin: "40px 0 0 0" }}>
        <CustomPagination
          className="pagination-bar"
          currentPage={page}
          totalCount={videoListDataCount}
          siblingCount={0}
          showPageLimit={false}
          pageLimitArray={false}
          pageSize={pageSize}
          onPageChange={setPage}
          handleChangePageLimit={false}
          loading={false}
        />
      </Box>

      {openVideoUploadDialog && (
        <UploadVideoInVideoList
          openVideoUploadDialog={openVideoUploadDialog}
          setOpenVideoUploadDialog={setOpenVideoUploadDialog}
          videoListData={videoListData}
          setVideoListData={setVideoListData}
          getAllVideos={getAllVideos}
        />
      )}
      {openDeleteVideoDialog && (
        <DeleteVideoFromVideoList
          videoId={selectedVideoId}
          setOpenDeleteVideoDialog={setOpenDeleteVideoDialog}
          videoListData={videoListData}
          setVideoListData={setVideoListData}
          getAllVideos={getAllVideos}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: 1400 }}
        open={showLoader}
        onClick={hideLoaderHandler}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default VideoListing;
