import { Box, Button, Grid, Typography, Badge } from "@mui/material";
import Sidebar from "../Components/Sidebar/sidebar";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import "./headerModule.css";
import AccountSettingsMenus from "../Screens/AccountSettingsMenus";
const MainLayout = ({ panelTitle, panelSubTitle, ...props }) => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#F4FAFE",
        padding: 2,
        height: "100vh",
        width: "100vw",
        overflowY: "hidden",
      }}
    >
      <Grid item lg={2}>
        <Sidebar />
      </Grid>
      <Grid item lg={10} height={"100%"} className="header">
        <Box overflow={"auto"} height={"100%"}>
          <Box className="header-wrapper">
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Box textAlign={"left"}>
                  <Typography component={"h1"} variant="titleText1">
                    {panelTitle}
                  </Typography>
                  <Typography
                    component={"p"}
                    variant="titleText2"
                    marginTop={1}
                  >
                    {panelSubTitle}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="profile-menu">
            <Box className="notification-container">
              <Box>
                <Button variant="white" sx={{padding:'12px', borderRadius:'10px'}}>
                  <Badge badgeContent={1} color="primary" max={99}>
                    <NotificationsNoneIcon size="large" />
                  </Badge>
                </Button>
              </Box>
            </Box>
           
              <Box  textAlign={"right"}>
               <AccountSettingsMenus/>
              </Box>
            </Box>
          </Box>

          <Box marginTop={3} paddingLeft={5} paddingRight={5} paddingBottom={5}>
            {props.children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MainLayout;
