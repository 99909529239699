const LoginService = async (username, password) => {
  let data = {
    user_name: username,
    password: password,
  };
  const response = await fetch(
    'https://smmobilebackend.imagesbazaar.appzlogic.com/api/admin/login',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  );
  return response;
};

export default LoginService;
