import React from "react";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CANCEL, OK, VIDEO_DELETED_SUCCESSFULLY, WANT_TO_PROCEED } from "../../lib/ValidationMessage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { STATUS_200 } from "../../data/constants";
import { updateCmsDataService } from "../../Services/services";
const DeleteVideo=({
  index,
  setOpenDeleteVideoDialog,
  recommendedVideos,
  setRecommendedVideos,
  associatedData,
  getCmsRecommendedVideos,
}) =>{
  const [showLoader, setShowLoader] = useState(false);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const closesDeleteVideoDialog = () => {
    setOpenDeleteVideoDialog(false);
  };
  const removeVideo = async () => {
    let arr = [...recommendedVideos];
    arr.splice(index, 1);
    setRecommendedVideos([...arr]);

    const payload = {
      parent_key: associatedData?.parent_key,
      key_name: associatedData?.key_name,
      key_value: JSON.stringify(arr),
    };
    showLoaderHandler();
    let response = await updateCmsDataService(payload);
    if (response.status === STATUS_200) {
      toast.success(VIDEO_DELETED_SUCCESSFULLY);
      hideLoaderHandler();
      closesDeleteVideoDialog();
      getCmsRecommendedVideos();
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
      closesDeleteVideoDialog();
    }
  };

  return (
    <>
      <CustomDialog
        title="Alert"
        handleClose={closesDeleteVideoDialog}
        actions={
          <Box>
            <Button className="cancel-btn" onClick={closesDeleteVideoDialog}>
              {CANCEL}
            </Button>
            <Button
              className="confirm-btn"
              onClick={() => {
                setOpenDeleteVideoDialog((prevState) => {
                  return { ...prevState, show: false };
                });
                removeVideo();
              }}
            >
              {OK}
            </Button>
          </Box>
        }
      >
        <Typography className="alert-text">{WANT_TO_PROCEED}</Typography>
      </CustomDialog>
    </>
  );
}

export default DeleteVideo;
