import React, { useEffect, useMemo, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "../Seminars/seminarModule.css";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { EXPORT } from "../../lib/ValidationMessage";
import { DATA_LIMIT, STATUS_200, STATUS_401 } from "../../data/constants";
import { exportSeminarUserListService, getStateService, seminarUserListService } from "../../Services/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import moment from "moment/moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RejectSeminarEnquiry from "../Seminars/RejectSeminarEnquiry";
import CloseIcon from "@mui/icons-material/Close";
import { attendeesSearchByFilter, downloadBlobXLSFile, gender } from "../../lib/helper";
import { useParams } from "react-router-dom";

export default function Attendees() {
  let { seminarId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const[state,setState]=useState([]);
  const [page, setPage] = useState(1);
  const [attendeesData, setAttendeesData] = useState([]);
  const [attendeesDataCount, setAttendeesDataCount] = useState(0);
  const [attendeesDataList, setAttendeesDataList] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState();
  const [seminarData, setSeminarData] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [searchByGender, setSearchByGender] = useState("");
  const [searchByState, setSearchByState] = useState("");
 
  const attendeesTableColumn = useMemo(
    () => [
     
      {
        accessorKey: "name",
        header: "NAME",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantName.charAt(0).toUpperCase() +
                row?.original?.participantName.slice(1)}
            </Typography>
          );
        },
      },
      {
        accessorKey: "email",
        header: "EMAIL",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantEmail}
            </Typography>
          );
        },
      },
      {
        accessorKey: "mobileNumber",
        header: "MOBILE NO.",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
                {row?.original?.participantMobile ? row?.original?.participantMobile : 'N/A'}
            </Typography>
          );
        },
      },
      {
        accessorKey: "gender",
        header: "GENDER",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantGender.charAt(0).toUpperCase() +
                row?.original?.participantGender.slice(1)}
            </Typography>
          );
        },
      },
    

      {
        accessorKey: "state",
        header: "STATE",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantState}
            </Typography>
          );
        },
      },
    ],
    []
  );
useEffect(()=>{
getStateList();
},[])
  useEffect(() => {
    getAttendeesData(page);
  }, [page,searchBy]);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const getStateList = async () => {
    showLoaderHandler();
    
    const response = await getStateService(
      
    );
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
      setState([...response?.data?.rows]);
        
        hideLoaderHandler();
      }
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message,{toastId:'token-expire'});
    } else {
      toast.error(response?.message,{toastId:'error'});
      hideLoaderHandler();
    }
  };
  const getAttendeesData = async (page) => {
    let payload = {
      seminar_id: seminarId,
      page: page,
      limit: DATA_LIMIT,
      status:'1'
    };
    if(searchTerm){
      payload.search=searchTerm;
    }
    if(searchByGender){
      payload.gender=searchByGender
    }
    if(searchByState){
      payload.state=searchByState
    }
    showLoaderHandler();
    const response = await seminarUserListService(payload);
    if (response.status === STATUS_200) {
      if (response.data.count !== 0) {
        let participantData = response.data.rows.map((item) => {
          return {
            // participantSeminarId: item.seminar_id,
            participantName: item.session_registered_user.name,
            participantGender: item.session_registered_user.gender,
            participantEmail: item.session_registered_user.email,
            participantMobile: item.session_registered_user.mobile,
            participantState: item.session_registered_user.state,
            // participantRegDate: ,
          };
        });
        setAttendeesData([...participantData]);
        setAttendeesDataList([...participantData]);
        setAttendeesDataCount(response.data.count);
        hideLoaderHandler();
      } else {
        toast.error(response?.message);
        hideLoaderHandler();
      }
    }
  };
  // Function to Export data
  const downloadXlsData = async () => { 
    const response = await exportSeminarUserListService(searchTerm,searchByGender,searchByState);
    if(response){
      downloadBlobXLSFile(response, `AttendeesList`);
    }
  };
  const searchByGenderHandle=(value)=>{
    setSearchByGender(value)
  }
    
    const selectStateHandle = (value) => {
      setSearchByState(value);
    };
  const searchByHandle = (value) => {
    setSearchBy(value);
   
  };
  const filterData = () => {
    
     if (searchTerm) {
      const filtered = attendeesDataList.filter(
        (item) =>
          item.participantName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.participantState
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.participantEmail.toLowerCase().includes(searchTerm.toLowerCase())
          ||  item.participantMobile == (searchTerm.toLowerCase())
      );
      setAttendeesData(filtered);
     getAttendeesData(1);
      setAttendeesDataCount(filtered.length);
    } else {
      getAttendeesData(page);
    }
  };
  const clearFilterHandle = async () => {
    setSearchTerm(""); // Reset search term to empty string immediately
    setSearchBy("");
    setSearchByGender("");
    setSearchByState("");
    const payload={
      seminar_id: seminarId,
      page:page,
      limit:pageSize,
      status:'1'
    }
    const response = await seminarUserListService(payload);
    if (response.status === STATUS_200) {
      if (response.data.count !== 0) {
        let participantData = response.data.rows.map((item) => {
          return {
            // participantSeminarId: item.seminar_id,
            participantName: item.session_registered_user.name,
            participantGender: item.session_registered_user.gender,
            participantEmail: item.session_registered_user.email,
            participantMobile: item.session_registered_user.mobile,
            participantState: item.session_registered_user.state,
            
          };
        });
        setAttendeesData([...participantData]);
        setAttendeesDataList([...participantData]);
        setAttendeesDataCount(response.data.count);
        hideLoaderHandler();
      } else {
        toast.error(response?.message,{toastId:'error'});
        hideLoaderHandler();
      }
    }
  };

  return (
    <>
      <Grid
        container
        className="search-container"
       spacing={1}
       sx={{marginLeft:'0', width:'100%'}}
      >
       
        <Grid item sm={2} >
          <TextField
            fullWidth
            id="searchByGender"
            name="searchByGender"
            label="Gender"
            placeholder="Select"
            select
            value={searchByGender}
            onChange={(e) => {
              searchByGenderHandle(e.target.value);
            }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            InputProps={{
              sx: {
                borderRadius: 5,
              },
            }}
          >
          
           {gender.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
          <Grid sm item={2}>
            <TextField
              fullWidth
              id="searchByState"
              name="searchByState"
              select
              label="State "
              placeholder="Select state"
              value={searchByState}
              onChange={(e) => {
                selectStateHandle(e.target.value);
              }}
              InputProps={{
                sx: {
                  borderRadius: 5,
                },
              }}
            >
              {state.map((option) => (
                <MenuItem key={option.state_id} value={option.state_name}>
                  {option.state_name}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
        <Grid item sm={3} display={'flex'} alignItems={'center'}>
          <FormControl variant="textFieldLight" sx={{ marginLeft: "0" }}>
            <Input
              className="inputType1"
              id="search-field"
              fullWidth
              type={"text"}
              placeholder="Search here"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  filterData(e.target.value);
                }
              }}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        
        </Grid>
        <Grid item sm={3} display={'flex'} alignItems={'center'}>
        <Button
            variant="blue"
            onClick={filterData}
            sx={{ marginLeft: "12px" }}
          >
            Submit
          </Button>
         
      
        <Button
                variant="blue"
                type="submit"
                sx={{
                  backgroundColor: "#464255",
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "#464255 !important",
                  },
                }}
                onClick={clearFilterHandle}
              >
                Clear Filter
              </Button>
        </Grid>
        <Grid item sm={1} textAlign={"right"}>
          <Box>
            <Button
              variant="blue"
              type="submit"
              onClick={downloadXlsData}
              download
            >
              {EXPORT}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12}>
          <CustomTable
            columns={attendeesTableColumn}
            data={attendeesData}
            positionActionsColumn={false}
            enableSorting={false}
            enableRowActions={false}
            enablePagination={false}
            enableTopToolbar={false}

          />
          <Box className="pagination-section">
            <CustomPagination
              className="pagination-bar"
              currentPage={page}
              totalCount={attendeesDataCount}
              siblingCount={0}
              showPageLimit={false}
              pageLimitArray={false}
              pageSize={pageSize}
              onPageChange={setPage}
              handleChangePageLimit={false}
              loading={false}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
