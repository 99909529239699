// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daily-sales-report-container{
    background-color: #fff;
    margin: 0.75rem 0;
}
.report-wrapper{
    padding: 12px 16px;
    border-bottom: 1px solid rgb(224, 224, 224);
}
.records-wrapper{
text-align: left;
margin-bottom: 1.25rem;
}
.records-wrapper h5{
    line-height: 1.334;
    letter-spacing: 0em;
    font-weight: 600;
}
.filter-wrapper{
    margin: 1.25rem 0;
    justify-content: space-between;
}
.btn-wrapper, .contributor-btn-wrapper{
    text-align: left;
}
.btn-wrapper button{
    margin: 0 0 0 1rem;
}
.contributor-btn-wrapper button{
    margin: 0 1rem 0 0;
}
.btn-wrapper button, .contributor-btn-wrapper button{ 
    font-size: 14px;
    border-radius: 10px;
    box-shadow: none;
    text-transform: none;
    font-weight: 600;
    padding: 10px 15px;
}
.search-wrapper .MuiGrid-root{
    margin: 0 1rem 0 0;
   
}
.search-wrapper{
    align-items: center;
}
.seminar-details{
    background: #fff !important;
    padding: 32px; 
    border-radius: 8px;
}
.seminar-details .MuiInputBase-root{
    border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Screens/Reports/report.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,2CAA2C;AAC/C;AACA;AACA,gBAAgB;AAChB,sBAAsB;AACtB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,8BAA8B;AAClC;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,kBAAkB;;AAEtB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,2BAA2B;IAC3B,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".daily-sales-report-container{\n    background-color: #fff;\n    margin: 0.75rem 0;\n}\n.report-wrapper{\n    padding: 12px 16px;\n    border-bottom: 1px solid rgb(224, 224, 224);\n}\n.records-wrapper{\ntext-align: left;\nmargin-bottom: 1.25rem;\n}\n.records-wrapper h5{\n    line-height: 1.334;\n    letter-spacing: 0em;\n    font-weight: 600;\n}\n.filter-wrapper{\n    margin: 1.25rem 0;\n    justify-content: space-between;\n}\n.btn-wrapper, .contributor-btn-wrapper{\n    text-align: left;\n}\n.btn-wrapper button{\n    margin: 0 0 0 1rem;\n}\n.contributor-btn-wrapper button{\n    margin: 0 1rem 0 0;\n}\n.btn-wrapper button, .contributor-btn-wrapper button{ \n    font-size: 14px;\n    border-radius: 10px;\n    box-shadow: none;\n    text-transform: none;\n    font-weight: 600;\n    padding: 10px 15px;\n}\n.search-wrapper .MuiGrid-root{\n    margin: 0 1rem 0 0;\n   \n}\n.search-wrapper{\n    align-items: center;\n}\n.seminar-details{\n    background: #fff !important;\n    padding: 32px; \n    border-radius: 8px;\n}\n.seminar-details .MuiInputBase-root{\n    border-radius: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
