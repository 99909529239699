import { Box } from '@mui/material';
import MainLayout from '../../../Layouts/MainLayout';
import ManagePassword from './ManagePassword';
import '../../ProfileSetting/profile.css';
const ManagePasswordContainer = () => {
  return (
    <MainLayout
      panelTitle={'Manage Password'}
      panelSubTitle={'Manage password of dashboard from here.'}
    >
    <Box component={'div'} className='update-password-container'>
    <ManagePassword />
    </Box>
     
    </MainLayout>
  );
};

export default ManagePasswordContainer;
