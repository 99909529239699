import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Login from './Screens/Login/login';
import AuthProvider from './context/AuthProvider';
import PrivateRoute from './Routes/PrivateRoute';
import Home from './Screens/Home/Home';
import { ThemeProvider } from '@mui/material';
import { customTheme } from './theme/custom-theme';
import DashboardContainer from './Screens/Dasboard';
import SeminarContainer from './Screens/Seminars';
import ManagePasswordContainer from './Screens/ProfileSetting/Password';
import ReportsContainer from './Screens/Reports';
import CmsContainer from './Screens/CMS';
import SeminarParticipantsList from './Screens/SeminarAttendees/SeminarParticipantsList';
import UpdatePasswordContainer from './Screens/ProfileSetting/Profile';
import InvitationSent from './Screens/Reports/InvitationSent';
import AttendedUsers from './Screens/Reports/AttendedUsers';
import SeminarDetails from './Screens/Reports/SeminarDetails';
import VideoListing from './Screens/CMS/VideoListing';
import UserContextProvider from './context/UserContext';
import SeminarUsers from './Screens/SeminarAttendees/SeminarUsers';
import SeminarUsersContainer from './Screens/SeminarAttendees';
function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <div className="App">
        <Router>
          <AuthProvider>
         <UserContextProvider>
            {/* <p>Homepage</p> */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<DashboardContainer />} />
                <Route path="/seminars" element={<SeminarContainer />} />
                <Route path="/reports" element={<ReportsContainer />} />
                <Route path="/manage-password" element={<ManagePasswordContainer />} />
                <Route path="/myAccount" element={<UpdatePasswordContainer />} />
                <Route path="/cms" element={<CmsContainer />} />
                <Route path="/seminar-participants/:seminarId" element={<SeminarUsersContainer />} />
                {/* <Route path="/invitation-sent/:id/:type" element={<InvitationSent />} /> */}
                <Route path="/invitation-sent/*" element={<InvitationSent />} />
                <Route path="/attended-user/*" element={<AttendedUsers />} />
              
                <Route path="/seminar-details/:seminarId" element={<SeminarDetails />} />
                </Route>
            </Routes>
         </UserContextProvider>
          </AuthProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
