// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-password-container,
.update-profile-container {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
}
.update-password-form {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
}
.update-profile-form{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
   
}
.login-space-divider {
  margin-top: 25px !important;
}
.main-login-form .MuiInputBase-root {
  border-radius: 12px;
}
.update-password-form .update-button,
.update-profile-form .update-button {
  font-size: 14px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  text-transform: none;
  font-weight: 600;
  padding: 10px 15px;
  margin: 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Screens/ProfileSetting/profile.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAkB;EAAlB,kBAAkB;AACpB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;;AAE1B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,mBAAmB;AACrB;AACA;;EAEE,eAAe;EACf,mBAAmB;EACnB;6EAC2E;EAC3E,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".update-password-container,\n.update-profile-container {\n  width: 100%;\n  background-color: #fff;\n  padding: 1rem;\n}\n.update-password-form {\n  margin-top: 25px;\n  display: flex;\n  flex-direction: column;\n  width: fit-content;\n}\n.update-profile-form{\n    margin-top: 25px;\n    display: flex;\n    flex-direction: column;\n   \n}\n.login-space-divider {\n  margin-top: 25px !important;\n}\n.main-login-form .MuiInputBase-root {\n  border-radius: 12px;\n}\n.update-password-form .update-button,\n.update-profile-form .update-button {\n  font-size: 14px;\n  border-radius: 10px;\n  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),\n    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);\n  text-transform: none;\n  font-weight: 600;\n  padding: 10px 15px;\n  margin: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
