import React, { useEffect, useState } from "react";
import { usePagination } from "../../hooks/usePagination";
import Search from "@mui/icons-material/Search";
import classnames from "classnames";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import "./pagination.css";
function CustomPagination(props) {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    loading,
    showPageLimit,
    pageLimitArray,
    handleChangePageLimit,
  } = props;

  const [paginationRange, setPaginationrange] = useState([]);
  const { isMobile } = false;
  const pRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const randomValue = Math.random();

  useEffect(() => {
    setPaginationrange(pRange);
  }, []);

  useEffect(() => {
    setPaginationrange(pRange);
  }, [currentPage, pageSize, totalCount]);

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 1 && paginationRange && paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  return (
    <Grid
      container
      sx={{
        padding: isMobile ? "8px" : "15px 12px 0",
        borderTop: "1px solid #EDEFEF",
      }}
    >
      <Grid
        item
        md={7}
        xs={12}
        style={{
          textAlign: "right",
          overflow: "hidden",
          paddingBottom: "10px",
          display: showPageLimit && "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ul className="pagination-container">
          <li
            className={classnames("pagination-item", {
              disabled: loading || currentPage === 1,
            })}
            onClick={onPrevious}
          >
            Previous
          </li>

          {paginationRange.length > 0 && (
            <>
              <li
                className={classnames("pagination-item", {
                  selected: paginationRange[0] === currentPage,
                })}
                onClick={() => onPageChange(paginationRange[0])}
              >
                {loading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  paginationRange[0]
                )}
              </li>

              <li
                className="pagination-item no-hover-effect"
                style={{ margin: 0 }}
              >
                out of
              </li>
              <li
                className="pagination-item no-hover-effect"
                style={{ margin: 0 }}
              >
                {lastPage}
              </li>
            </>
          )}
          <li
            className={classnames("pagination-item", {
              disabled: loading || currentPage === lastPage,
            })}
            onClick={onNext}
          >
            Next
          </li>
        </ul>
        {showPageLimit && (
            <select
              value={pageSize}
              onChange={(evt)=>{handleChangePageLimit(evt.target.value)}}
              className="select-input-type"
            >
              {pageLimitArray?pageLimitArray?.map((p)=>{
                return  <option value={p}>{p}</option>
              }):
              <>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              </>}
            </select>
          )}
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
        sx={{
          fontSize: isMobile ? "12px" : "14px",
          display: "flex",
          paddingRight: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            alignSelf: "center",
            paddingBottom: { md: "0", xs: "14px" },
            paddingTop: { md: "0", xs: "14px" },
          }}
        >
          Total Records: {totalCount}
        </Typography>
      </Grid>
      <Grid
        item
        md={2}
        xs={12}
        sx={{ fontSize: isMobile ? "12px" : "14px", display: "flex" }}
      >
        <TextField
          fullWidth
          placeholder="Search Page..."
          sx={{
            height:'fit-content',
            bgcolor: "#F3F3F3",
            borderRadius: 4,
            "& fieldset": { border: "none" },
          }}
          id={`searchPage_${randomValue}`}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              if (Number(e.target.value) > 0) {
                if (
                  typeof Number(e.target.value) == "number" &&
                  !Number.isNaN(Number(e.target.value)) &&
                  e.target.value &&
                  Number(e.target.value) <= lastPage
                ) {
                  onPageChange(Number(e.target.value));
                } else {
                  e.target.value = "";
                }
              }
            }
          }}
          onChange={(e) => {
            if (e.target.value == "") onPageChange(1);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position={"end"}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  let element = document.getElementById(
                    `searchPage_${randomValue}`
                  );
                  if (element) {
                    if (
                      typeof Number(element.value) == "number" &&
                      !Number.isNaN(Number(element.value)) &&
                      element.value &&
                      Number(element.value) <= lastPage
                    ) {
                      onPageChange(Number(element.value));
                    } else {
                      element.value = "";
                    }
                  }
                }}
              >
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

export default CustomPagination;
