import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { adminPanelImages } from '../../Assets/Images';

const Homepage = () => {
  const navigate = useNavigate();
  return (
    <Box
      display={'flex'}
      height={'100vh'}
      sx={{
        backgroundImage: `url(${adminPanelImages.homeBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      <Grid container>
        <Grid item lg={12} textAlign={'right'}>
          <Button
            sx={{
              color: '#fff',
              backgroundColor: '#FF8B0D',
              padding: '7px 12px',
              margin: '20px',
            }}
            onClick={() => {
              navigate('/login');
            }}
          >
            Superlogin
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Homepage;
