import React, { useEffect, useMemo, useState } from "react";
import "./seminarModule.css";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CustomTable from "../../Components/CustomTable/CustomTable";
import {
  exportRegisterEnquiryData,
  getRegisterEnquiryData,
  getStateService,
} from "../../Services/services";
import { STATUS_200, STATUS_401 } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./seminarModule.css";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import moment from "moment/moment";
import AssignSeminar from "./AssignSeminar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RejectSeminarEnquiry from "./RejectSeminarEnquiry";
import CloseIcon from "@mui/icons-material/Close";
import { EXPORT } from "../../lib/ValidationMessage";
import { ageGroups, downloadBlobXLSFile, gender, searchByFilter } from "../../lib/helper";
import { DataGrid } from "@mui/x-data-grid";
function RegEnquiry() {
  const [showLoader, setShowLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const[state,setState]=useState([]);
  const [page, setPage] = useState(1);
  const [enquiryData, setEnquiryData] = useState([]);
  const [enquiryDataCount, setEnquiryDataCount] = useState(0);
  const [enquiryDataList, setEnquiryDataList] = useState([]);
  const [pageLimitArray, setPageLimitArray] = useState([10, 20, 50, 100, 200]);
  const [assignPageShow, setAssignPageShow] = useState(false);
  const [ageGroup, setAgeGroup] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchByGender, setSearchByGender] = useState("");
  const [searchByState, setSearchByState] = useState("");
 
  const [showRejectConfirmationDialog, setShowRejectConfirmationDialog] =
    useState({
      show: false,
      value: "",
    });
  const [select, setSelection] = React.useState([]);

  const participantsTableColumn = useMemo(
    () => [
      {
        accessorKey: "regId",
        header: "REG. ID",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {" "}
              {row?.original?.enquiryId}
            </Typography>
          );
        },
      },
      {
        accessorKey: "name",
        header: "NAME",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.reg_user_name.charAt(0).toUpperCase() +
                row?.original?.reg_user_name.slice(1)}
            </Typography>
          );
        },
      },
      {
        accessorKey: "email",
        header: "EMAIL",
        size: 200,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">{row?.original?.email}</Typography>
          );
        },
      },
      {
        accessorKey: "mobileNo",
        header: "MOBILE NO",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">{row?.original?.mobile ? row?.original?.mobile : 'N/A'}</Typography>
          );
        },
      },
      {
        accessorKey: "birth_date",
        header: "Birth Date",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.birth_date}
            </Typography>
          );
        },
      },
      {
        accessorKey: "gender",
        header: "GENDER",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.gender.charAt(0).toUpperCase() +
                row?.original?.gender.slice(1)}
            </Typography>
          );
        },
      },
      {
        accessorKey: "regDate",
        header: "REG. DATE",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {moment(row?.original?.reg_date).format("YYYY-MM-DD")}
            </Typography>
          );
        },
      },

      {
        accessorKey: "state",
        header: "STATE",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">{row?.original?.state}</Typography>
          );
        },
      },
    ],
    []
  );
  const [rows, setRows] = useState([]);
  const columns = [
    { field: "id", headerName: "REG ID", width: 100 },
    { field: "name", headerName: "NAME", width: 150 },
    { field: "email", headerName: "EMAIL", width: 250 },
    { field: "mobile", headerName: "MOBILE NO.", width: 150 },
    { field: "birthDate", headerName: "BIRTH DATE", width: 150 },
    { field: "gender", headerName: "GENDER", width: 100 },
    { field: "regDate", headerName: "REG. DATE", width: 150 },
    { field: "state", headerName: "STATE", width: 200 },
    {
      field: "action",
      headerName: "Action",
      renderCell: ({ row }) =>  <IconButton
      aria-label="rejectEnquiry"
      color="primary"
      sx={{ marginLeft: "7px", marginRight: "7px" }}
      onClick={() => {
        handleRejectEnquiry(row?.id);
      }}
    >
      <CloseIcon />
    </IconButton> ,
      width: 150,
    },
  ];
  
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
useEffect(()=>{
  getStateList();
},[])
  useEffect(() => {
    getRegisterEnquiryList(page);
  }, [page, pageSize]);
  const getRegisterEnquiryList = async (page) => {
    showLoaderHandler();
    let payload = {
      page: page,
      limit: pageSize
    };
    if(searchTerm){
      payload.search=searchTerm
    }
    if(searchByGender){
      payload.gender=searchByGender
    }
    if(searchByState){
      payload.state=searchByState
    }
    if(ageGroup){
      payload.age_group=ageGroup
    }
    const response = await getRegisterEnquiryData(
      payload
    );
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
        let enquiryList = response.data.rows.map((item) => {
          return {
            id: item.session_registered_users_id,
            name: item.name,
           
            gender: item.gender.charAt(0).toUpperCase() + item.gender.slice(1),
            email: item.email,
            state: item.state,
            mobile: item.mobile ? item.mobile :'N/A',
            birthDate: item.birth_date,
            regDate: moment(item.created_at).format("DD-MM-YYYY"),
          };
        });
        setEnquiryData([...enquiryList]);
        setEnquiryDataList([...enquiryList]);
        setEnquiryDataCount(response.data.count);
        hideLoaderHandler();
      }
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message,{toastId:'token-expire'});
    } else {
      toast.error(response?.message,{toastId:'error'});
      hideLoaderHandler();
    }
  };
  const getStateList = async () => {
    showLoaderHandler();
    
    const response = await getStateService(
      
    );
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
      setState([...response?.data?.rows]);
        
        hideLoaderHandler();
      }
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message,{toastId:'token-expire'});
    } else {
      toast.error(response?.message,{toastId:'error'});
      hideLoaderHandler();
    }
  };
  const handleRejectEnquiry = (enquiryId) => {
    setShowRejectConfirmationDialog({
      show: true,
      value: enquiryId,
    });
  };
  // Function to Export data
  const downloadXlsData = async () => {
    const response = await exportRegisterEnquiryData(searchTerm,searchByGender,searchByState,ageGroup);
    if (response) {
      downloadBlobXLSFile(response, `EnquiryList`);
    }
  };
  const searchByHandle = (value) => {
    setSearchBy(value);
    setAgeGroup(value);
  };
const searchByGenderHandle=(value)=>{
  setSearchByGender(value)
}
  const selectAgeGroupHhandle = (value) => {
    setAgeGroup(value);
  };
  const selectStateHandle = (value) => {
    setSearchByState(value);
  };

  // Function to filter data based on search term
  const filterData = () => {
   
     if (searchTerm) {
      const filtered = enquiryDataList.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.mobile == (searchTerm.toLowerCase())
      );
      setEnquiryData(filtered);
      getRegisterEnquiryList(1);
      setEnquiryDataCount(filtered.length);
    } else {
      getRegisterEnquiryList(page);
    }
  };
const handleRowSelection=(ids)=>{
  setSelection(ids);
  
}
const clearFilterHandle = async () => {
 
  searchByHandle("");
  setSearchBy("");
  setAgeGroup("");
  setSearchByGender("");
  setSearchByState("");
  setSearchTerm(""); // Reset search term to empty string immediately
  let payload = {
    page: page,
    limit: pageSize
  };
 
 
  const response = await getRegisterEnquiryData(
    payload
  );
  if (response.status === STATUS_200) {
    if (response.data.length !== 0) {
      let enquiryList = response.data.rows.map((item) => {
        return {
          id: item.session_registered_users_id,
          name: item.name,
          gender: item.gender,
          email: item.email,
          state: item.state,
          mobile: item.mobile,
          birthDate: item.birth_date,
          regDate: moment(item.created_at).format("DD-MM-YYYY"),
        };
      });
      setEnquiryData([...enquiryList]);
      setEnquiryDataList([...enquiryList]);
      setEnquiryDataCount(response.data.count);
      hideLoaderHandler();
    }
  } else if (response.status === STATUS_401) {
    window.location.href = "/";
    localStorage.clear();
    toast.error(response?.message);
  } else {
    toast.error(response?.message);
    hideLoaderHandler();
  }
}
  return (
    <>
      <Grid
        container
        spacing={1}
        className="search-container"
        alignItems={"center"}
        margin={0}
        display= {select?.length > 0 ? 'none' :''}
      >
 
          <Grid item sm={3} >
          <TextField
            fullWidth
            id="searchByGender"
            name="searchByGender"
            label="Gender"
            placeholder="Select"
            select
            value={searchByGender}
            onChange={(e) => {
              searchByGenderHandle(e.target.value);
            }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            InputProps={{
              sx: {
                borderRadius: 5,
              },
            }}
          >
          
           {gender.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
          <Grid item sm={3}>
            <TextField
              fullWidth
              id="searchByState"
              name="searchByState"
              select
              label="State "
              placeholder="Select state"
              value={searchByState}
              onChange={(e) => {
                selectStateHandle(e.target.value);
              }}
              InputProps={{
                sx: {
                  borderRadius: 5,
                },
              }}
            >
              {state.map((option) => (
                <MenuItem key={option.state_id} value={option.state_name}>
                  {option.state_name}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
            <Grid item sm={3}>
            <TextField
              fullWidth
              id="ageGroup"
              name="ageGroup"
              select
              label="Age Group"
              placeholder="Select Age Group"
              value={ageGroup}
              onChange={(e) => {
                selectAgeGroupHhandle(e.target.value);
              }}
              InputProps={{
                sx: {
                  borderRadius: 5,
                },
              }}
            >
              {ageGroups.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
            <Grid  item sm={3}></Grid>
          <Grid item sm={9} display={"flex"} alignItems={"center"}>
            <FormControl variant="textFieldLight" sx={{marginLeft:'0'}}>
              <Input 
                className="inputType1"
                id="search-field"
                fullWidth
                type={"text"}
                placeholder="Search here"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    filterData(e.target.value);
                  }
                }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="blue"
              onClick={filterData}
              sx={{ marginLeft: "12px" }}
            >
              Submit
            </Button>
            <Button
                variant="blue"
                type="submit"
                sx={{
                  backgroundColor: "#464255",
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "#464255 !important",
                  },
                }}
                onClick={clearFilterHandle}
              >
                Clear Filter
              </Button>
          </Grid>
        

        <Grid item sm={3} textAlign={"right"}>
          <Box>
            <Button variant="blue" onClick={downloadXlsData}>
              {EXPORT}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container className="reg-table-container">
        <Grid
          item
          sm={12}
          className="assign-sem-container"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
         
        >
          {select?.length > 0 ? <Box sx={{color:'black', fontSize:'14px', fontWeight:'400'}}>{select?.length} row(s) selected</Box> : ""}
          <Box
            component={"div"}
            textAlign={"end"}
            width={select?.length < 1 ? "100%" : "auto"}
          >
            <Button
              className="approve-btn"
              variant="blue"
              disabled={select?.length < 1}
              onClick={()=>setAssignPageShow(true)}
            >
              Assign Seminar
            </Button>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <DataGrid className="reg-data-grid"
            sx={{  height:'600px', width: "100%", margin: "8px 0", background:"#fff" }}
            rows={enquiryData}
            columns={columns}
            localeText={{ noRowsLabel: "No records to display" }}
            // checkboxSelectionVisibleOnly
            checkboxSelection={true}
            disableColumnSorting={true}
            disableColumnFilter={true}
            disableColumnResize={true}
            disableColumnSelector={true}
            disableRowSelectionOnClick={true}
            disableColumnMenu={true}
            disableDensitySelector={true}
            disableAutosize={true}
            onRowSelectionModelChange={(ids) => handleRowSelection(ids)}
          />
        </Grid>
        <Grid item sm={12}>
          <Box textAlign={"right"}>
            {/* <CustomTable
              columns={participantsTableColumn}
              data={enquiryData}
            
              positionActionsColumn="last"
              enableSorting={false}
              enableRowSelection={true}
              positionToolbarAlertBanner="top"
              enableRowActions={true}
              enablePagination={false}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Actions",
                  size: 100,
                },
              }}
              renderRowActions={({ row }) => (
                <Box>
                  <IconButton
                    aria-label="rejectEnquiry"
                    color="primary"
                    sx={{ marginLeft: "7px", marginRight: "7px" }}
                    onClick={() => {
                      handleRejectEnquiry(row.original.enquiryId);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              renderTopToolbarCustomActions={({ table }) => {
                let arr = [];
             
                const handleApprove = () => {
                  table.getSelectedRowModel().flatRows.map((row) => {
                    arr.push(row.original.enquiryId);
                   
                    setSelectedRow(arr);
                  });
                  setAssignPageShow(true);
                };
                setSelectedRowsCount(
                  table.getSelectedRowModel().flatRows.length
                );
                return (
                  <>
                    <Box component={"div"} className="top-toolbar">
                      <Button
                        className="approve-btn"
                        variant="blue"
                        disabled={!table.getSelectedRowModel().flatRows.length}
                        onClick={() => {
                          handleApprove();
                        }}
                      >
                        Assign Seminar
                      </Button>
                    </Box>
                  </>
                );
              }}
            /> */}
            <Box className="pagination-section" marginTop={'54px'}>
              <CustomPagination
                className="pagination-bar"
                currentPage={page}
                totalCount={enquiryDataCount}
                siblingCount={0}
                showPageLimit={true}
                pageLimitArray={pageLimitArray}
                pageSize={pageSize}
                onPageChange={setPage}
                handleChangePageLimit={(event) => {
                  setPageSize(event);
                }}
                loading={false}
              />
            </Box>
          </Box>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      {assignPageShow && (
        <AssignSeminar
          selectedId={select}
          assignPageShow={assignPageShow}
          getRegisterEnquiryList={getRegisterEnquiryList}
          setAssignPageShow={setAssignPageShow}
        />
      )}
      {showRejectConfirmationDialog.show && (
        <RejectSeminarEnquiry
          data={showRejectConfirmationDialog.value}
          setShowRejectConfirmationDialog={setShowRejectConfirmationDialog}
          fetchEnquiryList={getRegisterEnquiryList}
        />
      )}
    </>
  );
}
export default RegEnquiry;
