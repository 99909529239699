import React from "react";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { CANCEL, IMAGE_DELETED_SUCCESSFULLY, OK, WANT_TO_PROCEED } from "../../lib/ValidationMessage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { useEffect } from "react";
import { STATUS_200 } from "../../data/constants";
import { removeBannerImageService  } from "../../Services/services";
const DeleteBannerImage=({
  deleteBannerDialog,
  setDeleteBannerDialog,
  bannerUrl,
  getCmsBannerImage
})=> {
  const [showLoader, setShowLoader] = useState(false);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  useEffect(()=>{
    getCmsBannerImage();
  },[])
  const closesDeleteBannerDialog = () => {
    setDeleteBannerDialog(false);
  };
  
  const removeBannerImage = async () => {
    const payload={
      image_name:bannerUrl,
      folder_name:'banner_image'
    }
    showLoaderHandler();
    const response = await removeBannerImageService(payload);
    if (response.status === STATUS_200) {
      hideLoaderHandler();
      toast.success(IMAGE_DELETED_SUCCESSFULLY);
     closesDeleteBannerDialog();
      getCmsBannerImage();
    } else {
      toast.error(response?.message);
      closesDeleteBannerDialog();
      hideLoaderHandler();
    }
  };

  return (
    <>
      <CustomDialog
       handleOpen={deleteBannerDialog}
        title="Alert"
        handleClose={closesDeleteBannerDialog}
        actions={
          <Box>
            <Button className="cancel-btn" onClick={closesDeleteBannerDialog}>
              {CANCEL}
            </Button>
            <Button
              className="confirm-btn"
              onClick={() => {
                setDeleteBannerDialog((prevState) => {
                  return { ...prevState, show: false };
                });
                removeBannerImage();
              }}
            >
              {OK}
            </Button>
          </Box>
        }
      >
        <Typography className="alert-text">{WANT_TO_PROCEED}</Typography>
      </CustomDialog>
      <ToastContainer containerId={'del-img'}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default DeleteBannerImage;
