import React from 'react';
import { MaterialReactTable } from 'material-react-table';

const CustomTable = ({ ...props }) => {
  return (
    <MaterialReactTable
      enableDensityToggle={false}
      enableColumnActions={false}
      enableSorting={false}
      enableColumnFilters={false}
      enableGlobalFilter={false}
      enableHiding={false}
      enableFullScreenToggle={false}
      enableTopToolbar={true}
      enableExpandAll={false}
      positionActionsColumn={'last'}
      positionExpandColumn={'last'}
      defaultColumn={{
        minSize: 20, //allow columns to get smaller than default
        maxSize: 500, //allow columns to get larger than default
        size: 80, //make columns wider by default
      }}
      // icons={{
      // KeyboardDoubleArrowDownIcon: ()=>null,
      // ExpandMoreIcon: ()=>null,
      // }}
      muiTablePaperProps={{
        elevation: 0, //change the mui box shadow
        //customize paper styles
      }}
      initialState={{
        columnVisibility: { 'mrt-row-expand': false },
        ...props?.initialState,
      }}
      muiTableHeadCellProps={{
        sx: {
          fontSize: '0.875rem',
          fontFamily: 'Poppins, sans-serif',
          color: '#A3A3A3',
          fontWeight: 600,
        },
      }}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: 'Actions', //change header text
          size: 400, //make actions column wider
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        'mrt-row-expand': {
          header: '', //change header text
          size: 50, //make actions column wider
        },
        'mrt-row-select': {
          header: '', //change header text
          size: 50, //make actions column wider
        },

        ...props?.displayColumnDefOptions,
      }}
      {...props}
    />
  );
};

export default CustomTable;
