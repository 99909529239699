import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import CustomTable from "../../Components/CustomTable/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import { seminarUserListService } from "../../Services/services";
import { STATUS_200, DATA_LIMIT } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Seminars/seminarModule.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";
import {
  GO_BACK,
  NO_PARTICIPANTS,
  SUBMIT,
  WELCOME_TO_ADMIN_DASHBOARD,
} from "../../lib/ValidationMessage";

const InvitationSent = () => {
  let navigate = useNavigate();
  // let { id,type } = useParams();
  const location = useLocation();
  const [params, setParams] = useState({
    semId: "",
    gender: "",
  });

  function getIdAndGender() {
    const queryString = location.pathname.split("/invitation-sent/")[1];
    const params = new URLSearchParams(queryString);
    const id = params.get("id");
    const gender = params.get("gender");
    showParticipants(page, id, gender);
    setParams({
      semId: id,
      gender: gender,
    });
    return { id, gender };
  }
 
  const [showLoader, setShowLoader] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [participantDataList, setParticipantDataList] = useState([]);
  const [pageSize, setPageSize] = useState(DATA_LIMIT);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [participantsDataCount, setParticipantsDataCount] = useState(0);

  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  // useEffect(()=>{

  // },[])
  useEffect(() => {
    getIdAndGender();
  }, [page]);
  const showParticipants = async (page, id, gender) => {
    let payload = {
      seminar_id: id,
      page: page,
      limit: DATA_LIMIT,
      search: searchTerm,
    };
    if (gender) {
      payload.gender = gender;
    }
    showLoaderHandler();
    const response = await seminarUserListService(payload);
    if (response.status === STATUS_200) {
      // if (response.data.count !== 0) {
      let participantData = response.data.rows.map((item) => {
        return {
          participantName: item.session_registered_user.name,
          participantGender: item.session_registered_user.gender,
          participantEmail: item.session_registered_user.email,
          participantState: item.session_registered_user.state,
          participantMobile: item.session_registered_user.mobile,
        };
      });
      setParticipantList([...participantData]);
      setParticipantDataList([...participantData]);
      setParticipantsDataCount(response.data.count);
      hideLoaderHandler();
      // }
      // else {
      //   if(!toast.isActive('toast-error')){
      //   toast.error(NO_PARTICIPANTS,{toastId:'toast-error'});
      //   }
      //   hideLoaderHandler();
      // }
    } else if (response.status === 401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
  const participantsTableColumn = useMemo(
    () => [
      {
        acceaccessorKey: "participantName",
        header: "Participant Name",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantName}
            </Typography>
          );
        },
      },

      {
        acceaccessorKey: "participantEmail",
        header: "E-mail Address",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantEmail}
            </Typography>
          );
        },
      },
      {
        accessorKey: "participantMobileNumber",
        header: "Mobile No.",
        size: 100,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantMobile
                ? row?.original?.participantMobile
                : "N/A"}
            </Typography>
          );
        },
      },
      {
        acceaccessorKey: "participantGender",
        header: "Gender",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantGender}
            </Typography>
          );
        },
      },

      {
        acceaccessorKey: "pariticipantState",
        header: "State",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography variant="tableCell">
              {row?.original?.participantState}
            </Typography>
          );
        },
      },
    ],
    []
  );
  // Function to filter data based on search term
  const filterData = () => {
    if (searchTerm) {
      const filtered = participantDataList.filter(
        (item) =>
          item.participantName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.participantState
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.participantEmail
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.participantMobile == searchTerm.toLowerCase()
      );
      setParticipantList(filtered);
      showParticipants(1, params.semId, params.gender);
      setParticipantsDataCount(filtered.length);
    } else {
      showParticipants(page, params.semId, params.gender);
    }
  };
  const clearFilterHandle = async () => {
    setSearchTerm(""); // Reset search term to empty string immediately
    let payload = {
      seminar_id: params.semId,
      page: page,
      limit: DATA_LIMIT,
    };

    if (params.gender) {
      payload.gender = params.gender;
    }
    showLoaderHandler();
    const response = await seminarUserListService(payload);
    if (response.status === STATUS_200) {
      let participantData = response.data.rows.map((item) => {
        return {
          participantName: item.session_registered_user.name,
          participantGender: item.session_registered_user.gender,
          participantEmail: item.session_registered_user.email,
          participantState: item.session_registered_user.state,
          participantMobile: item.session_registered_user.mobile,
        };
      });
      setParticipantList([...participantData]);
      setParticipantDataList([...participantData]);
      setParticipantsDataCount(response.data.count);
      hideLoaderHandler();
    } else if (response.status === 401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
  return (
    <>
      <MainLayout
        panelTitle={"Invited User List"}
        panelSubTitle={WELCOME_TO_ADMIN_DASHBOARD}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          marginTop={2}
          marginBottom={2}
        >
          <KeyboardBackspaceIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <Typography marginLeft={2}>{GO_BACK}</Typography>
        </Box>

        <Grid container className="search-container">
          <Grid item sm={8} display={"flex"} alignItems={"center"}>
            <FormControl variant="textFieldLight" sx={{ marginLeft: "0" }}>
              <Input
                className="inputType1"
                id="search-field"
                fullWidth
                type={"text"}
                placeholder="Search here"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    filterData(e.target.value);
                  }
                }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="blue"
              onClick={filterData}
              sx={{ marginLeft: "12px" }}
            >
              {SUBMIT}
            </Button>
            <Button
              variant="blue"
              type="submit"
              sx={{
                backgroundColor: "#464255",
                borderRadius: 3,
                "&:hover": {
                  backgroundColor: "#464255 !important",
                },
              }}
              onClick={clearFilterHandle}
            >
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Grid container className="invitation-sent-container">
          <Grid item sm={12}>
            <CustomTable
              columns={participantsTableColumn}
              data={participantList}
              positionActionsColumn="last"
              enableSorting={true}
              enablePagination={false}
              enableRowActions={false}
              renderRowActions={false}
            />
            <Box className="pagination-section">
              <CustomPagination
                className="pagination-bar"
                currentPage={page}
                totalCount={participantsDataCount}
                siblingCount={0}
                showPageLimit={false}
                pageLimitArray={false}
                pageSize={pageSize}
                onPageChange={setPage}
                handleChangePageLimit={false}
                loading={false}
              />
            </Box>
          </Grid>
        </Grid>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </MainLayout>
    </>
  );
};
export default InvitationSent;
