import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export default function CustomTabs({
  count,
  activeTab,
  tabList,
  urlKeyPram = "tab",
  tabClick,
  sx,
  ...props
}) {
  const [value, setValue] = React.useState(1);
  const [searchParams, setSearchParams] = useSearchParams({ [urlKeyPram]: 1 });
  const currentParams = Object.fromEntries([...searchParams]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({
      ...currentParams,
      [urlKeyPram]: newValue,
    });
    generateTabClick(newValue);
  };

  const generateTabClick = (value) => {
    let data = tabList.filter((item) => item.id === value);
    tabClick(data[0]);
  };

  React.useEffect(() => {
    try {
      // let tab = searchParams.get(urlKeyPram);
      let tab = activeTab;
      setValue(Number(tab) || 1);
      generateTabClick(Number(tab) || 1);
    } catch (e) {}
  }, [searchParams,count]);

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant={props.variant}
       
        allowScrollButtonsMobile
        {...props.tabsProp}
      >
        {tabList?.map((item, index) => {
          return (
            <Tab
              label={item.label}
              value={item.id}
              key={item.id}
              sx={{
                fontSize: "14px",
                lineHeight: "30px",
                fontFamily: "Poppins",
                fontWeight: "500",
                color: "#BCBEBE",
                letterSpacing: "0.4px",
              }}
              className={`${
                tabList.length > 1 && tabList.length - 1 !== index
                  ? "Add-border"
                  : ""
              }`}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
