import MainLayout from '../../Layouts/MainLayout';
import CmsSection from './Cms';
const CmsContainer = () => {
  return (
    <MainLayout panelSubTitle={'Manage content of website from here.'} panelTitle={'CMS'}>
      <CmsSection />
    </MainLayout>
  );
};

export default CmsContainer;
