import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  GO_BACK,
  WELCOME_TO_ADMIN_DASHBOARD,
  SEMINAR_UPDATED_SUCCESSFULLY,
  CANCEL,
} from "../../lib/ValidationMessage";
import { ReactComponent as EditIcon } from "../../Assets/Svg/edit.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { React, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  fetchSeminarsService,
  updateSeminarService,
} from "../../Services/services";
import {
  STATUS_200,
  FETCH_FAIL,
  STATUS_401,
} from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Seminars/seminarModule.css";
import {
  AGE_GROUP_REQUIRED,
  CITY_REQUIRED,
  DESCRIPTION_REQUIRED,
  DURATION_REQUIRED,
  LESS_THAN_24HRS_VALIDATION,
  LEVEL_REQUIRED,
  MIN_1_SEAT_REQUIRED,
  MORE_THAN_1_VALIDATION,
  NUMBER_REQUIRED,
  SEMINARNAME_3_CHARACTERS_REQUIRED,
  SEMINARNAME_REQUIRED,
  SEMINAR_DATE_CANNOT_BE_LESS_THAN_CURRENT_DATE,
  SEMINAR_DATE_REQUIRED,
  SEMINAR_SCHEDULE_REQUIRED,
  SEMINAR_SEATS_REQUIRED,
  SEMINAR_TIME_REQUIRED,
  SEMINAR_VENUE_REQUIRED,
  SUBMIT,
} from "../../lib/ValidationMessage";
import "./report.css";
import { cities } from "../../lib/helper";
import { ageGroups } from "../../lib/helper";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";

function SeminarDetails() {
  let navigate = useNavigate();
  const current_date = new Date();
  let { seminarId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [enableState, setEnableState] = useState(false);
  const [seminarDetails, setSeminarDetails] = useState(null); // Initialize as null
  
  useEffect(() => {
    fetchSeminarsList();
  }, []);
  
  const fetchSeminarsList = async () => {
    const payload = {
      seminar_id: seminarId,
    };
    showLoaderHandler();
    const response = await fetchSeminarsService(payload);
    if (response.status === STATUS_200) {
      if (response.data.rows.length !== 0) { // Check length of rows array
        setSeminarDetails(response.data.rows[0]);
      }
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
    }
    hideLoaderHandler();
  };

  const showLoaderHandler = () => {
    setShowLoader(true);
  };

  const hideLoaderHandler = () => {
    setShowLoader(false);
  };

  const updateSeminar = async () => {
    let payload = {
      seminar_id: seminarId,
      name: formik.values.seminarName,
      age: formik.values.ageGroup,
      city: formik.values.seminarCity,
      date: moment(formik.values.seminarDate).format("DD-MM-YYYY"),
    
      time: formik.values.seminarTime,
      seat: formik.values.seminarSeats,
      venue: formik.values.seminarVenue,
      duration: formik.values.seminarDuration,
      description: formik.values.seminarDescription,
    };
    showLoaderHandler();
    let response = await updateSeminarService(payload);
    if (response.status === STATUS_200) {
      toast.success(SEMINAR_UPDATED_SUCCESSFULLY);
      fetchSeminarsList(); // Reload seminar details after update
      setEnableState(false)
      formik.resetForm();
    } else if (response.status === FETCH_FAIL) {
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
    }
    hideLoaderHandler();
  };

  useEffect(() => {
    if (seminarDetails) {
      editSeminar();
    }
  }, [seminarDetails]);

  const editSeminar = () => {
    formik.setValues({
      seminarName: seminarDetails.name,
      ageGroup: seminarDetails.age,
      seminarCity: seminarDetails.city,
      seminarDate: moment(seminarDetails.date).format("DD-MM-YYYY"),
      seminarTime: seminarDetails.time,
      seminarSeats: seminarDetails.seat,
      seminarVenue: seminarDetails.venue,
      seminarDuration: seminarDetails.duration.match(/\d+/)[0], // Extract duration value
      seminarDescription: seminarDetails.description,
    });
  };

  const formik = useFormik({
    initialValues: {
      seminarName: "",
      ageGroup: "",
      seminarCity: "",
      seminarDate: "",
      seminarTime: "",
      seminarSeats: "",
      seminarVenue: "",
      seminarDuration: "",
      seminarDescription: "",
    },
    validationSchema: Yup.object({
      seminarName: Yup.string()
        .min(3, SEMINARNAME_3_CHARACTERS_REQUIRED)
        .required(SEMINARNAME_REQUIRED),
      ageGroup: Yup.string().required(AGE_GROUP_REQUIRED),
      seminarCity: Yup.string().required(CITY_REQUIRED),
      seminarDate: Yup.date()
        .min(new Date(), SEMINAR_DATE_CANNOT_BE_LESS_THAN_CURRENT_DATE)
        .required(SEMINAR_DATE_REQUIRED),
      seminarTime: Yup.string().required(SEMINAR_TIME_REQUIRED),
      seminarSeats: Yup.number()
        .min(1, MIN_1_SEAT_REQUIRED)
        .required(SEMINAR_SEATS_REQUIRED),
      seminarVenue: Yup.string()
        .min(3, SEMINARNAME_3_CHARACTERS_REQUIRED)
        .required(SEMINAR_VENUE_REQUIRED),
      seminarDuration: Yup.number()
        .required(DURATION_REQUIRED)
        .min(1, MORE_THAN_1_VALIDATION)
        .max(24, LESS_THAN_24HRS_VALIDATION), // Changed lessThan to max
      seminarDescription: Yup.string()
        .min(3, SEMINARNAME_3_CHARACTERS_REQUIRED)
        .required(DESCRIPTION_REQUIRED),
    }),
    onSubmit: updateSeminar,
  });
const cancelEditing=()=>{
  setEnableState(false);
 editSeminar();
}
  return (
    <>
      <MainLayout
        panelTitle={"Seminar Details"}
        panelSubTitle={WELCOME_TO_ADMIN_DASHBOARD}
      >
        <Grid container>
          <Grid item sm={12}>
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                marginTop={2}
                marginBottom={2}
              >
                <KeyboardBackspaceIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Typography marginLeft={2}>{GO_BACK}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          component={"form"}
          className="seminar-details"
          
        >
          <Grid container spacing={3} justifyContent={"center"}>
          <Grid item md={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarName"
              label="Seminar Name"
              placeholder="Seminar Name"
              value={seminarDetails?.name}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={3} lg={3}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarDate"
              label="Seminar Date"
              placeholder="Seminar Date"
              value={moment(seminarDetails?.date).format("DD-MM-YYYY")}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={3} lg={3}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarTime"
              label="Seminar Time"
              placeholder="Seminar Time"
              value={seminarDetails?.time}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={2} lg={2}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarDuration"
              label="Seminar Duration"
              placeholder="Seminar Duration"
              value={seminarDetails?.duration}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarAgeGroup"
              label="Seminar Age Group"
              placeholder="Seminar Age Group"
              value={seminarDetails?.age}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarCity"
              label="Seminar City"
              placeholder="Seminar City"
              value={seminarDetails?.city}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarVenue"
              label="Seminar Venue"
              placeholder="Seminar Venue"
              value={seminarDetails?.venue}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>

          <Grid item md={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarTotalSeats"
              label="Seminar Seats"
              placeholder="Seminar Seats"
              value={seminarDetails?.seat}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarMaleSeats"
              label="Seminar Male Seats"
              placeholder="Seminar Male Seats"
              value={seminarDetails?.male_seats}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>
          <Grid item md={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="seminarfemaleSeats"
              label="Seminar Female Seats"
              placeholder="Seminar Female Seats"
              value={seminarDetails?.female_seats}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: "rounded",
                },
              }}
              disabled
            />
          </Grid>

          <Grid item md={12} lg={12}>
            <TextField
              fullWidth
              id="seminarDescription"
              name="seminarDescription"
              label="Seminar Description"
              placeholder="Type your Description"
              value={seminarDetails?.description}
              multiline
              rows={4}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                sx: {
                  borderRadius: 5,
                },
              }}
              disabled
            />
          </Grid>
          </Grid>
        </Box>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </MainLayout>
    </>
  );
}

export default SeminarDetails;
