import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import "./report.css";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { useState } from "react";
import { DATA_LIMIT, STATUS_200 } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchSeminarsService, seminarReportListService } from "../../Services/services";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import moment from "moment";
function Reports() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(DATA_LIMIT);
  const [page, setPage] = useState(1);
  const [reportSeminarData, setReportSeminarData] = useState([]);
  const [reportSeminarDataList, setReportSeminarDataList] = useState([]);
  const [reportSeminarDataCount, setReportSeminarDataCount] = useState();
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const seminarReport = useMemo(
    () => [
      {
        acceaccessorKey: "seminarName",
        header: "Seminar Name",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Link
              onClick={() => {
                navigate(`/seminar-details/${row?.original?.seminarId}`);
              }}
            >
              {row?.original?.seminarName}
            </Link>
          );
        },
      },
      {
        acceaccessorKey: "seminarDate",
        header: "Seminar Date",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography
            
            >
              {moment(row?.original?.seminarDate).format('DD-MM-YYYY')}
            </Typography>
          );
        },
      },
      {
        acceaccessorKey: "seminarCity",
        header: "Seminar City",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography
            
            >
              {row?.original?.seminarCity}
            </Typography>
          );
        },
      },
      {
        acceaccessorKey: "seminarVenue",
        header: "Seminar Venue",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <Typography
            
            >
              {row?.original?.seminarVenue}
            </Typography>
          );
        },
      },
      {
        acceaccessorKey: "inviteSent",
        header: "Invite Sent",
        size: 100,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <>
             <Typography>
             Total:{' '}
             <Link
              onClick={() => {
                // navigate(`/invitation-sent/${row?.original?.seminarId}`);
                navigate(`/invitation-sent/id=${row?.original?.seminarId}`);
              }}
            >
             {row?.original?.seminarInvitationTotal}
            </Link>
             </Typography>
           
            <Typography>
            Male: {' '}
            <Link
              onClick={() => {
                // navigate(`/invitation-sent/${row?.original?.seminarId}/female`);
                navigate(`/invitation-sent/id=${row?.original?.seminarId}&gender=male`);
              }}
            >
           
            {row?.original?.seminarInvitationMale}
            </Link>
            </Typography>
           
           <Typography>
           Female: {' '}
           <Link
              onClick={() => {
                //  navigate(`/invitation-sent/${row?.original?.seminarId}/female`);
                navigate(`/invitation-sent/id=${row?.original?.seminarId}&gender=female`);
              }}
            >
           {row?.original?.seminarInvitationFemale}
            </Link>
           </Typography>
            
            </>
           
          );
        },
      },
      {
        acceaccessorKey: "attended",
        header: "Seminar Attended",
        size: 50,
        enableSorting: true,
        Cell: ({ cell, row }) => {
          return (
            <>
           <Typography>
           Total:{' '}
           <Link
              onClick={() => {
                navigate(`/attended-user/id=${row?.original?.seminarId}`);
              }}
            >
             {row?.original?.seminarAttendeesTotal}
            </Link>
           </Typography>  
           
            <Typography>
            Male: {' '}
            <Link
              onClick={() => {
                navigate(`/attended-user/id=${row?.original?.seminarId}&gender=male`);
              }}
            >
           
            {row?.original?.seminarAttendeesMale}
            </Link>
            </Typography>
           
            <Typography>
            Female: {' '}
            <Link
              onClick={() => {
                navigate(`/attended-user/id=${row?.original?.seminarId}&gender=female`);
              }}
            >
          
            {row?.original?.seminarAttendeesFemale}
            </Link>
            </Typography>
            
            </>
            
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    fetchSeminarsReportList(page);
  }, [page]);
  const fetchSeminarsReportList = async (page) => {
    const payload={
      page:page,
      limit:pageSize
    }
    if(searchTerm){
      payload.search=searchTerm
    }
    showLoaderHandler();

    const response = await seminarReportListService(payload);
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
        let seminarList = response.data.rows.map((item) => {
          return {
            seminarId: item.seminar_id,
            seminarName: item.name,
            seminarDate: item.date,
            seminarCity: item.city,
            seminarVenue: item.venue,
            seminarInvitationTotal: item?.invitation_count,
            seminarInvitationMale: item?.male_invitation_count,
            seminarInvitationFemale: item?.female_invitation_count,
            seminarAttendeesTotal: item?.attendees_count,
            seminarAttendeesMale: item?.male_attendees_count,
            seminarAttendeesFemale: item?.female_attendees_count,
          };
        });
        setReportSeminarData([...seminarList]);
        setReportSeminarDataList([...seminarList]);
        setReportSeminarDataCount(response.data.count);
        hideLoaderHandler();
      }
    } else if (response.status === 401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };
  const filterData = () => {
    if (searchTerm) {
      const filtered = reportSeminarDataList.filter(
        (item) =>
          item.seminarName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
            ||  item.seminarCity.includes(searchTerm.toLowerCase())  ||
            item.seminarVenue.includes(searchTerm.toLowerCase()) 
      );
      setReportSeminarData(filtered);
      fetchSeminarsReportList(1);
      setReportSeminarDataCount(filtered.length);
    } else {
      fetchSeminarsReportList(page);
    }
  };

  const clearFilterHandle = async () => {
    setSearchTerm(""); // Reset search term to empty string immediately
    const payload={
      page:page,
      limit:pageSize
    }
    const response = await seminarReportListService(payload);
    if (response.status === STATUS_200) {
      if (response.data.length !== 0) {
        let seminarList = response.data.rows.map((item) => {
          return {
           
            seminarId: item.seminar_id,
            seminarName: item.name,
            seminarDate: item.date,
            seminarCity: item.city,
            seminarVenue: item.venue,
             seminarInvitationTotal: item?.invitation_count,
            seminarInvitationMale: item?.male_invitation_count,
            seminarInvitationFemale: item?.female_invitation_count,
            seminarAttendeesTotal: item?.attendees_count,
            seminarAttendeesMale: item?.male_attendees_count,
            seminarAttendeesFemale: item?.female_attendees_count,
          };
        });
        setReportSeminarData([...seminarList]);
        setReportSeminarDataList([...seminarList]);
        setReportSeminarDataCount(response.data.count);
        hideLoaderHandler();
      }
    } else if (response.status === 401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };

  return (
    <>
      <Box component="div" className="daily-sales-report-container">
        <Box component="div" className="report-wrapper">
          <Grid container className="search-wrapper" marginTop={2}>
            <Grid item sm={4} md={4} lg={3}>
              <TextField
                fullWidth
                label="Search Text"
                placeholder="Search Text"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    filterData(e.target.value)
                  }
                }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                required
              />
            </Grid>
            <Grid item sm={4} md={6} lg={8} className="btn-wrapper">
              <Button
                variant="blue"
                type="submit"
                onClick={filterData}
                sx={{
                  borderRadius: 3,
                }}
              >
                Submit
              </Button>
              <Button
                variant="blue"
                type="submit"
                sx={{
                  backgroundColor: "#464255",
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "#464255 !important",
                  },
                }}
                onClick={clearFilterHandle}
              >
                Clear Filter
              </Button>
              
            </Grid>
          </Grid>
        </Box>
        <CustomTable
          columns={seminarReport}
          data={reportSeminarData}
          positionActionsColumn="last"
          enableSorting={false}
          enableRowActions={false}
          enableTopToolbar={false}
          enablePagination={false}
          rowCount={false}
          
        />
        <Box className="pagination-section">
          <CustomPagination
            className="pagination-bar"
            currentPage={page}
            totalCount={reportSeminarDataCount}
            siblingCount={0}
            showPageLimit={false}
            pageLimitArray={false}
            pageSize={pageSize}
            onPageChange={setPage}
            handleChangePageLimit={false}
            loading={false}
          />
        </Box>
      </Box>
    </>
  );
}

export default Reports;
