import loginPageBackground from './loginBackground.jpg';
import dashboardLogoWhite from './dashboardLogoWhite.svg';
import dashboardLogoOrange from './dashboardLogoOrange.png';
import homeBackground from './homeBackground.jpg';
export const adminPanelImages = {
  loginPageBackground,
  dashboardLogoWhite,
  homeBackground,
  dashboardLogoOrange
};
