import {
  Backdrop,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { useEffect } from "react";
import { STATUS_200, STATUS_401 } from "../../data/constants";
import {
  getCmsDataService
} from "../../Services/services";
import UploadNewVideo from "./uploadNewVideo";
import DeleteVideo from "./DeleteVideo";
import { Link, useNavigate } from "react-router-dom";
function RecommendedVideos({setActiveTabOne}) {
  const navigate = useNavigate();
  const [openVideoUploadDialog, setOpenVideoUploadDialog] = useState(false);
  const [openDeleteVideoDialog, setOpenDeleteVideoDialog] = useState(false);
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState();
  const [videooAssociatedData, setVideosAssociatedData] = useState({
    setting_id: "",
    parent_key: "",
    key_name: "",
  });
  const [activeTab,setActiveTab]=useState(1);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    getCmsRecommendedVideos();
}, []);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const getCmsRecommendedVideos = async () => {
    showLoaderHandler();
    const response = await getCmsDataService("recommend_videos");
    if (response.status === STATUS_200) {
      let recVideoList = JSON.parse(response?.data[0]?.key_value);
     const videoDataList=[...Object.values(recVideoList)]
      setRecommendedVideos(videoDataList.reverse());
      setVideosAssociatedData({
        setting_id: response?.data[0]?.setting_id,
        parent_key: response?.data[0]?.parent_key,
        key_name: response?.data[0]?.key_name,
      });
      hideLoaderHandler();
    } else if (response.status === STATUS_401) {
      window.location.href = "/";
      localStorage.clear();
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
      hideLoaderHandler();
    }
  };
  const removeVideoDialog = (i) => {
    setOpenDeleteVideoDialog(true);
    setSelectedVideoIndex(i);
  };
  const btnClick=()=>{
    setActiveTab(2);
setActiveTabOne(2)
  }
  
  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        className="content-container"
      >
        <Grid item xs={3} md={3} lg={3}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "1.334",
              letterSpacing: "0em",
              margin: "16px 0",
            }}
          >
            Recommended Videos Section
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3} textAlign={"end"} className="save-btn">
          <Button variant="blue" onClick={() => setOpenVideoUploadDialog(true)}>
            Upload New Video
          </Button>
         <Link to={''} variant="blue" style={{marginLeft:'8px'}} onClick={btnClick}>View All</Link>
        </Grid>
      </Grid>
      <Grid container>
        {recommendedVideos.slice(0, 3).map((data, i) => {
              var regExp =
                /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
              var match = data.match(regExp);
              const url = `https://www.youtube.com/embed/${match[2]}`;
              
              return (
                <React.Fragment key={i}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Card sx={{ height: "250px", borderRadius: "16px" }}>
                      <CardActionArea
                        sx={{ height: "100%", borderRadius: "16px" }}
                      >
                        <CardMedia
                          component="iframe"
                          height="100%"
                          src={url}
                          title="YouTube video"
                        />
                      </CardActionArea>
                    </Card>{" "}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className="save-btn"
                    margin={"24px 0"}
                  >
                    <Button disabled={showLoader ? true : false}
                      variant="red"
                      onClick={() => {
                        removeVideoDialog(i);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </React.Fragment>
              );
            })
         }
      </Grid>
      {openVideoUploadDialog && (
        <UploadNewVideo
          openVideoUploadDialog={openVideoUploadDialog}
          setOpenVideoUploadDialog={setOpenVideoUploadDialog}
          associatedData={videooAssociatedData}
          recommendedVideos={recommendedVideos}
          setRecommendedVideos={setRecommendedVideos}
          getCmsRecommendedVideos={getCmsRecommendedVideos}
        />
      )}
      {openDeleteVideoDialog && (
        <DeleteVideo
          index={selectedVideoIndex}
          setOpenDeleteVideoDialog={setOpenDeleteVideoDialog}
          recommendedVideos={recommendedVideos}
          setRecommendedVideos={setRecommendedVideos}
          associatedData={videooAssociatedData}
          getCmsRecommendedVideos={getCmsRecommendedVideos}
        />
      )}

      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: 1400 }}
        open={showLoader}
        onClick={hideLoaderHandler}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default RecommendedVideos;
