import React from "react";
import {
  URL_IS_REQUIRED,
  VIDEO_UPDATED_SUCCESSFULLY,
} from "../../lib/ValidationMessage";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import CustomDialog from "../../Components/Custom Dialog/CustomDialog";
import { useFormik } from "formik";
import * as Yup from "yup";
import { STATUS_200 } from "../../data/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { insertNewVideosService, updateCmsDataService } from "../../Services/services";
import { useState } from "react";
const UploadVideoInVideoList = ({
  openVideoUploadDialog,
  setOpenVideoUploadDialog,
  videoListData,
  setVideoListData,
  getAllVideos,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  const hideVideoUploadModal = () => {
    setOpenVideoUploadDialog(false);
  };
  const handleVideoUpload = async () => {
    // let arr = [...videoListData];
    // arr.push(formik.values.video_url);
    // setVideoListData([...arr]);

    const payload = {
        video_url :formik?.values?.video_url
    };
    showLoaderHandler();
    let response = await insertNewVideosService(payload);
    if (response.status === STATUS_200) {
      toast.success(VIDEO_UPDATED_SUCCESSFULLY);
      hideLoaderHandler();
      formik.resetForm();
      hideVideoUploadModal();
      getAllVideos();
    } else {
      toast.error(response?.message);
      hideVideoUploadModal();
      hideLoaderHandler();
    }
  };
  const formik = useFormik({
    initialValues: {
      video_url: "",
    },
    validationSchema: Yup.object({
      video_url: Yup.string().required(URL_IS_REQUIRED),
    }),
    onSubmit: handleVideoUpload,
  });

  return (
    <>
      <CustomDialog
        handleOpen={openVideoUploadDialog}
        title="Upload New Video"
        fullWidth={true}
        maxWidth={"sm"}
        showClose={true}
        handleClose={hideVideoUploadModal}
      >
        <Box
          component={"form"}
          onSubmit={formik.handleSubmit}
          noValidate
          marginTop={1}
        >
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                type="text"
                name="video_url"
                id="video_url"
                className="upload-video-url"
                label="URL"
                placeholder="Enter URL"
                value={formik.values.video_url}
                onChange={formik.handleChange}
                error={
                  formik.touched.video_url && Boolean(formik.errors.video_url)
                }
                helperText={formik.touched.video_url && formik.errors.video_url}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    borderRadius: 5,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              lg={12}
              sx={{
                textAlign: "right",
              }}
            >
              <Button
                variant="blue"
                type="submit"
                size="large"
                sx={{
                  borderRadius: 3,
                }}
              >
                Submit
              </Button>
              <Button
                variant="grey"
                onClick={hideVideoUploadModal}
                size="large"
                sx={{
                  borderRadius: 3,
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </>
  );
};

export default UploadVideoInVideoList;
