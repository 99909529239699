// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: #fff;
} */
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-transition: background-color 600000s 0s, color 600000s 0s;
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
.login-form .Mui-error{
    color: #fff !important;
}

.login-form .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #fff !important;
} `, "",{"version":3,"sources":["webpack://./src/Components/Login/loginPage.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;;IAEI,iEAAyD;IAAzD,yDAAyD;AAC7D;AACA;IACI,wCAAwC;AAC5C;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":["/* input:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n    transition: background-color 5000s ease-in-out 0s;\n    color: #fff;\n} */\ninput:-webkit-autofill,\ninput:-webkit-autofill:focus {\n    transition: background-color 600000s 0s, color 600000s 0s;\n}\ninput[data-autocompleted] {\n    background-color: transparent !important;\n}\n.login-form .Mui-error{\n    color: #fff !important;\n}\n\n.login-form .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline{\n    border-color: #fff !important;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
