import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getCmsDataService, updateCmsDataService } from '../../Services/services';
import { STATUS_200, STATUS_401 } from '../../data/constants';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cms.css";
import { CONTENT_UPDATED_SUCCESSFULLY } from '../../lib/ValidationMessage';
import { Backdrop, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
function Content() {
    const [showLoader, setShowLoader] = useState(false);
    const [maxCharacter, setMaxCharacter] = useState(1000);
    const [homepageContent, setHomepageContent] = useState({
        parent_key: "",
        key_name: "",
        key_value: "",
      });
    useEffect(() => {
     
        getCmsHomepageContent();
      }, []);
      const hideLoaderHandler = () => {
        setShowLoader(false);
      };
      const showLoaderHandler = () => {
        setShowLoader(true);
      };
     
    const getCmsHomepageContent = async () => {
        showLoaderHandler();
        const response = await getCmsDataService("homepage_content");
        if (response.status === STATUS_200) {
          setHomepageContent({
            parent_key: response?.data[0]?.parent_key,
            key_name: response?.data[0]?.key_name,
            key_value: response?.data[0]?.key_value,
          });
          hideLoaderHandler();
        } else if (response.status === STATUS_401) {
          window.location.href = "/";
          localStorage.clear();
          toast.error(response?.message);
        } else {
          toast.error(response?.message);
          hideLoaderHandler();
        }
      };
    
     
    const updateHomepageContent = async () => {
        const payload = {
          parent_key: homepageContent?.parent_key,
          key_name: homepageContent?.key_name,
          key_value: homepageContent?.key_value,
        };
     
        var regex = /^\s/;
        if(regex.test(homepageContent?.key_value )){
          if (!toast.isActive('cannot-empty')) {
            toast.error('Content cannot be empty', { toastId: 'cannot-empty' })
          }
            
    }
   else if(homepageContent?.key_value == ''){
      if (!toast.isActive('cannot-empty')) {
        toast.error('Content cannot be empty', { toastId: 'cannot-empty' })
      }
        
}
    else{
     
        showLoaderHandler();
        const response = await updateCmsDataService(payload);
        if (response.status === STATUS_200) {
          hideLoaderHandler();
          if (!toast.isActive('success-toast')) {
          toast.success(CONTENT_UPDATED_SUCCESSFULLY, { toastId: 'success-toast' });
          }
          getCmsHomepageContent();
        } else {
          if (!toast.isActive('error-toast')) {
          toast.error(response?.message, { toastId: 'error-toast' });

          }
          hideLoaderHandler();
        }
    }
      };
  return (
    <>
         <Grid container className="content-container">
          <Grid item xs={12} md={8} lg={8}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "1.334",
                letterSpacing: "0em",
                margin: "16px 0",
              }}
            >
              Content Section
            </Typography>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <TextField
              fullWidth
              id="content"
              name="content"
              label="Content"
              placeholder="Write here..."
              value={homepageContent?.key_value}
              onChange={(e) => {
                setHomepageContent((prev) => {
                  return { ...prev, key_value: e.target.value };
                });
              }}
              multiline
              rows={6}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: maxCharacter }}
              InputProps={{
                sx: {
                  borderRadius: 5,
                },
              maxLength: 50
              }}
              required
            />
            <Typography component={'small'} sx={{fontSize:'12px', marginLeft:'8px'}}>{homepageContent?.key_value.length} {" "}out of {" "}{maxCharacter} characters</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className="save-btn">
            <Button variant="blue" onClick={updateHomepageContent} disabled={showLoader ? true : false}>
              Save
            </Button>
          </Grid>
        </Grid>
        <ToastContainer containerId={'content'}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: 1400 }}
          open={showLoader}
          onClick={hideLoaderHandler}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}

export default Content;